import * as React from 'react';
import withStyles from 'react-jss';
import { Catalog, RouterProps } from '../library/Types'; // eslint-disable-line
import { Modal, Button, message} from 'antd'; 
import {ActionTypes } from './utils';
import { useGenericState } from '../library/UseGenericState';
import { deleteCatalog, updateCatalog } from '../api/apis';


interface IProps extends RouterProps {
  classes: any;
  handleClose: (isSucess?:boolean) => void;
  isVisible: boolean;
  action: string;
  catalog: Catalog;
  fetchCatalogsData: () => void;
}

const styles = {
  modal: {
    '& .ant-modal-content': {
      borderRadius: '8px',
      width: 'fit-content',
      height: '250px',
      boxShadow: '2px 2px 8px 0 rgba(0,0,0,0.09)'
    },
    '& .ant-modal-body': {
      padding: '9px 16px',
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'space-around'
    }
  },
  removeButton: {
    color: '#111111',
    backgroundColor: '#FFD17B',
    border: '1px solid #111111',
    marginRight: '16px',
    '&:focus': {
      color: '#111111',
      backgroundColor: '#FFD17B',
      border: '1px solid #111111'
    },
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#111111',
      border: '1px solid #111111'
    }
  },
  cancelButton:{
    color: '#111111',
    '&:hover': {
        color: '#111111',
    }
  },
  text: {
    color: '#111111',
    fontSize: '18px',
    letterSpacing: 0,
    lineHeight: '19px',
    marginBottom: '4px'
  },
  head: {
    color: '#111111',
    fontSize: '24px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '18px'
      
  },
  hr:{
    height: '1px',
    width: '400px',
    transform: 'scaleY(-1)',
    backgroundColor: '#EDEDED'
  },
  buttonWrapper:{
    display: 'flex'
  }
};

interface StateProps {
  isUpdatingCatalog: boolean;
  isDeletingCatalog: boolean;
}

const CatalogStatusConfirmModal = (props: IProps): React.ReactElement => {
  const { classes, handleClose, isVisible, action, catalog, fetchCatalogsData } = props;
  const [state, setState] = useGenericState<StateProps>({
    isUpdatingCatalog: false,
    isDeletingCatalog: false
  });

  const { isUpdatingCatalog, isDeletingCatalog } = state;

  const editCatalog = async () => {
    setState({isUpdatingCatalog: true});
    const response = await updateCatalog({...catalog, isActive: !catalog.isActive}, catalog.id);
    if(response.isSuccess){
      message.success(`Catalog ${action === ActionTypes.ACTIVATE ? 'Published' : 'Unpublished'} Successfully!`);
      handleClose();
      fetchCatalogsData();
    } else {
      message.error(response.errorMessage);
    }
    setState({isUpdatingCatalog: false});
  }

  const removeCatalog = async () => {
    setState({isDeletingCatalog: true});
    const response = await deleteCatalog(catalog.id);
    if(response.isSuccess){
      message.success(`Catalog Removed Successfully!`);
      handleClose();
      fetchCatalogsData();
    } else {
      message.error(response.errorMessage);
    }
    setState({isDeletingCatalog: false});
  }

  return (
    <Modal
      className={classes.modal}
      visible={isVisible}
      onCancel={() => handleClose()}
      closable={false}
      footer={null}
      centered
    > 
        <div className={classes.head}>{action === ActionTypes.DELETE ? 'Remove' : action === ActionTypes.ACTIVATE ? 'Activate' : 'Deactivate'} Catalog</div>
        <div>
            <p className={classes.text}>Are you sure you want to {action === ActionTypes.DELETE ? 'Remove' : action === ActionTypes.ACTIVATE ? 'publish' : 'unpublish'} this catalog?</p>
            {(action === ActionTypes.DEACTIVATE || action === ActionTypes.DELETE) && <p style={{marginBottom: '0px', position: 'relative', top: '20px'}}>Note: The previous orders of this catalog will still be active</p>}
        </div>
        
        <div className={classes.hr}></div>
        <div className={classes.buttonWrapper}>
            <Button onClick={() => action === ActionTypes.DELETE ? removeCatalog() : editCatalog()} className={classes.removeButton} loading={isUpdatingCatalog || isDeletingCatalog}>Yes! {action === ActionTypes.DELETE ? 'Remove' : action === ActionTypes.ACTIVATE ? 'Activate' : 'Deactivate'}</Button>
            <Button onClick={() => handleClose()} className={classes.cancelButton}>Cancel</Button>  
        </div>
    </Modal>
  );
};

export default withStyles(styles)(CatalogStatusConfirmModal);