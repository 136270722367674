import React, { useEffect } from 'react';
import withStyles from 'react-jss';
import { Modal, Button, Input, Form, Upload, Icon, Tooltip, message, Spin } from 'antd';
import { styles } from '../styles/createcatalog.styles';
import Cross from '../cheerIcons/Cross';
import { FormComponentProps } from 'antd/lib/form';
import { useGenericState } from '../library/UseGenericState';
import { ActionTypes, getFileExtension } from './utils';
import { createCatalog, fileUploadOnS3, updateCatalog, uploadImageToS3 } from '../api/apis';
import { Catalog } from '../library/Types';
import { UploadFile } from 'antd/lib/upload/interface';

interface Props extends FormComponentProps<any> {
  classes: any;
  handleClose: () => void;
  isVisible: boolean;
  fetchCatalogsData: () => void;
  action: string;
  catalog: Catalog
}

interface State {
  previewVisible: boolean;
  previewImage: string;
  fileList: UploadFile<any>[];
  isPublishingCatalog: boolean;
  isUpdatingCatalog: boolean;
}

const CreateCatalogModal = (props: Props): React.ReactElement => {
  const { classes, handleClose, isVisible, form, fetchCatalogsData, action, catalog } = props;
  const { getFieldDecorator } = form;
  const [state, setState] = useGenericState<State>({
    previewVisible: false,
    previewImage: '',
    fileList: action === ActionTypes.CREATE ? [] : catalog?.imageUrls.map((imageUrl:any, idx) => {
      return {
        uid: idx.toString(),
        name: '',
        size: 0,
        type: '',
        url: imageUrl
      }
    }),
    isPublishingCatalog: false,
    isUpdatingCatalog: false
  });

  const { fileList, previewVisible, previewImage, isPublishingCatalog, isUpdatingCatalog } = state;

  function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const handleChange = ({fileList}:any) => {
    setState({fileList})
  }

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  const handleCancel = () => setState({ previewVisible: false });

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const catalog = {
          title: form.getFieldValue('item name'),
          price: Number(form.getFieldValue('item price')),
          description: form.getFieldValue('item description'),
          // @ts-ignores Object is possibly 'undefined'
          imageUrls: fileList.map(file => file.originFileObj ? file.originFileObj?.url : file.url)
        };
        if(action === ActionTypes.CREATE) publishCatalog(catalog);
        else editCatalog(catalog)
      } 
    });
  }

  const uploadPicture = async ({file, onSuccess, onError}: any) => {
    const extension = getFileExtension(file.name);
    const response = await fileUploadOnS3({
      extension,
      file: file.name,
      document_type: file.type,
      initialPath: 'beer-for-cheer'
    });
    if (response.isSuccess) {
      const upload = await uploadImageToS3(file, response.data.post, extension);
      if (upload.isSuccess) {
        file.url = response.data.get;
        onSuccess()
      } else {
        onError()
      }
    } else {
      onError()
    }
  };

  const publishCatalog = async (catalog: Catalog) => {
    setState({isPublishingCatalog: true});
    const response = await createCatalog(catalog);
    if(response.isSuccess){
      message.success('Catalog Published Successfully!');
      handleClose();
      fetchCatalogsData();
    } else {
      message.error(response.errorMessage);
    }
    setState({isPublishingCatalog: false});
  }

  const editCatalog = async (body: Catalog) => {
    setState({isUpdatingCatalog: true});
    const response = await updateCatalog(body, catalog.id);
    if(response.isSuccess){
      message.success(`Catalog ${action === ActionTypes.CREATE ? 'Published' : 'Updated'} Successfully!`);
      handleClose();
      fetchCatalogsData();
    } else {
      message.error(response.errorMessage);
    }
    setState({isUpdatingCatalog: false});
  }

  return (
    <Modal
      className={classes.modal}
      visible={isVisible}
      onCancel={handleClose}
      closable={false}
      footer={null}
    >
      <div className={classes.parent}>
        <div className={classes.header}>
          <div className={classes.leftHeader}>
            <div className={classes.cross} onClick={handleClose}><Cross/></div>
            <div className={classes.catalogModalTitle}>{action === ActionTypes.CREATE ? 'Add New' : 'Edit'} Item</div>
          </div>
          <Tooltip title={!fileList.length ? 'Please Upload Atleast One Image File ' : ''}>
            <Button className={classes.publishButton} htmlType='submit' form='catalogForm' disabled={!fileList.length} loading={isPublishingCatalog || isUpdatingCatalog}>
              <span className={classes.publish}>{action === ActionTypes.CREATE ? 'Publish' : 'Save' }</span>
            </Button>
          </Tooltip>
        </div>
        <Form id='catalogForm' layout='vertical' onSubmit={handleSubmit}>
          <div className={classes.body}>
            <div className={classes.itemInfo}>
              <div className={classes.itemName}>
                <Form.Item label='Item Name'>
                {getFieldDecorator('item name', {
                    rules: [{ required: true, message: 'Please input the catalog name!' }],
                    initialValue: action === ActionTypes.UPDATE ? catalog?.title : ''
                  })(
                    <Input placeholder="Item Name" />
                  )}
                </Form.Item>   
              </div>
              <div className={classes.priceWrapper}>
                <Form.Item label='Price'>
                {getFieldDecorator('item price', {
                    rules: [
                      { required: true, message: 'Please input the catalog price!' },
                      { type: 'number', message: 'Please input valid price!', 
                        transform(value) {
                          return Number(value)
                        }
                      }
                    ],
                    initialValue: action === ActionTypes.UPDATE ? catalog?.price : ''
                  })(
                    <Input placeholder="0" suffix={<div>Beers</div>}/>
                  )}
                </Form.Item>  
              </div>
            </div>
            <div className={classes.itemDescription}>
              <Form.Item label='Item Description'>
              {getFieldDecorator('item description', {
                  rules: [{ required: true, message: 'Please input the catalog description!' }],
                  initialValue: action === ActionTypes.UPDATE ? catalog?.description : ''
                })(
                  <Input placeholder="Item Description" />
                )}
              </Form.Item>  
            </div>
            <div className={classes.itemImagesWrapper}>
                <Form.Item label='Item Image (upto 4)'>
                  <>
                    <Upload
                      customRequest={file => uploadPicture(file)}
                      accept={'.jpg, .jpeg, .png'}
                      listType="picture-card"
                      fileList={fileList}
                      onChange={handleChange}
                      onPreview={handlePreview}
                    >
                      {fileList.length >= 4 ? null : uploadButton}
                    </Upload>
                    <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                      <img alt="example" style={{ width: '100%' }} src={previewImage} />
                    </Modal>
                  </>          
              </Form.Item>  
            </div>
          </div>  
        </Form>
      </div>
    </Modal>
  );
};

export default Form.create<Props>()(withStyles(styles)(CreateCatalogModal));