export const styles = {
  modal: {
    minWidth: '680px',
    minHeight: '460px',
    '& .ant-modal-content': {
      borderRadius: '16px'
    },
    '& .ant-modal-body': {
      padding: '0px !important',
      textAlign: 'center',
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      border: '1px solid #F2F2F2',
      display: 'flex',
      alignItems: 'center'
    }
  },
  parent: {
    display: 'flex',
    flexDirection: 'column',
    width: '-webkit-fill-available'
  },
  header:{
    display: 'flex',
    justifyContent: 'space-between',
    padding: '22px 24px',
    alignItems: 'center',
    borderBottom: '1px solid #CCCCCC'
  },
  leftHeader:{
    display: 'flex',
    alignItems: 'center'
  },
  cross:{
    marginRight: '16px',
    fontSize: '16px',
    display: 'flex',
    cursor: 'pointer'
  },
  title: {
    color: '#333333',
    fontSize: '16px',
    fontWeight: 500
  },
  button: {
    borderRadius: '6px',
    backgroundColor: '#111111',
    color: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:focus': {
        color: '#FFFFFF',
        backgroundColor: 'rgba(1,1,1,0.8)',
    },
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: 'rgba(1,1,1,0.8)',
    },
  },
  buyNow: {
    color: '#FFFFFF',
    fontSize: '12px',
    letterSpacing: '0.2px',
    lineHeight: '20px',
    textAlign: 'center'
  },
  body: {
    display: 'flex',
    flexDirection: 'column'
  },
  orderDetailsWrapper: {
    display: 'flex',
    padding: '24px'
  },
  orderDetails:{
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  orderDetailsTitle:{
    color: '#333333',
    fontSize: '16px',
    marginBottom: '12px'
  },
  orderDetailsInfo:{
    display: 'flex'
  },
  catalogImage:{
    width: '70px',
    height: '70px',
    borderRadius: '5px',
    marginRight: '12px',
    objectFit: 'cover'
  },
  catalogInfo:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  catalogTitle: {
    color: '#121212',
    fontSize: '14px'
  },
  catalogPrice:{
    color: '#121212'
  },
  remark:{
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    alignItems: 'flex-start'
  },
  remarkTitle: {
    color: '#333333',
    fontSize: '16px',
    marginBottom: '12px'
  },
  selectAddressHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '24px 24px 16px 24px'
  },
  addressWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '6px',
    marginBottom: '32px',
    padding: '0px 22px',
    height: '220px',
    overflowY: 'auto'
  },
  addNewAddress: {
    cursor: 'pointer'
  },
  address: {
    padding: '10px 16px',
    border: '1px solid #CCCCCC',
    borderRadius: '4px',
    fontSize: '12px',
    textAlign: 'start',
    cursor: 'pointer',
    '&:hover':{
      backgroundColor: '#FFF9E5'
    },
    position: 'relative'
  },
  deleteIcon: {
    display: 'none',
    width: 'fit-content',
    position: 'absolute',
    bottom: '6px',
    right: '10px',
    '&:hover': {
      cursor: 'pointer'
    },
    ':hover > &': {
      display: 'block'
    }
  },
  editIcon: {
    display: 'none',
    width: 'fit-content',
    position: 'absolute',
    bottom: '6px',
    right: '30px',
    '&:hover': {
      cursor: 'pointer'
    },
    ':hover > &': {
      display: 'block'
    }
  },
  emptyAddressState: {
    padding: '50px 0'
  },
  buyNowDisabled:{
    color: '#D3D3D3',
    fontSize: '12px',
    letterSpacing: '0.2px',
    lineHeight: '20px',
    textAlign: 'center'
  }
};