import * as React from 'react';
import CatalogCardsWrapper from './CatalogCardsWrapper';
import { MERCHANDISE_NAVBAR_SPACE } from '../globals';
import Loader from '../cheerIcons/Loader.gif';
import { styles } from '../styles/merchandise.styles';
import withStyles from 'react-jss';
import BackButton from '../cheerIcons/BackButton';
import { getDashboardRoute } from '../routing/utils';

const MerchandiseSection = (props: any) => {
  const { history, classes, redeemableBeers } = props;

  return (
    <div className={classes.parentDiv} style={{ minHeight: window.innerHeight - MERCHANDISE_NAVBAR_SPACE }}>
        <div className={classes.topBar}>
          <div className={classes.leftFlex}>
            <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={() => history.push(getDashboardRoute())}><BackButton/></div>
            <span style={{marginLeft: '10px', fontSize: '16px'}}>Welcome To Merchandise Platform. Go Crazy!</span></div>
          <div className={classes.rightFlex}>
            <span className={classes.beerValue}>
              { redeemableBeers ?? 
                <div style={{ display: 'inline-block' }}>
                  <img src={Loader} alt="" style={{ height: '25px' }}/>
                </div>
              } Beers
            </span>
            <span className={classes.beerTitle}>Chiller Storage</span>
          </div>
        </div>
        <CatalogCardsWrapper isAdmin={false} redeemableBeers={redeemableBeers}/>
      </div>
  );
}

export default React.memo(withStyles(styles)(MerchandiseSection));