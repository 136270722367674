import * as React from 'react';
import { Switch, Route, withRouter } from 'react-router';
import App from './App';
import Login from './components/Login';
import { getLoginRoute, getLogoutRoute, getSelectTeamRoute } from './routing/utils';
import Logout from './components/Logout';
import GAuthPage from './components/GauthPage';
import TeamSelectPage from './components/TeamSelectPage';
import { BrowserRouter } from 'react-router-dom';

const Main = () => {
  return (
    <BrowserRouter basename="/">
      <Switch>
        <Route path={getLoginRoute()} component={Login} />
        <Route path={getLogoutRoute()} component={Logout} />
        <Route path="/oauth" component={GAuthPage} />
        <Route path={getSelectTeamRoute()} component={TeamSelectPage} />
        <Route component={App} />
      </Switch>
    </BrowserRouter>
  );
};

export default withRouter(Main);
