import { GET, POST, PUT , DELETE, PATCH} from './apiHandler';
import {
  LOGIN,
  FETCH_USERS,
  CHEERS,
  FETCH_CHEERS_FEED,
  CHEERS_FEED_REACT,
  FETCH_ACTIVITIES,
  POLL_REACTIONS,
  FETCH_INSIGHTS,
  FETCH_RANK,
  FETCH_FAQS,
  FETCH_LEADER_BOARD,
  GET_S3_UPLOAD_LINKS,
  UPLOAD_PROFILE_PIC,
  FETCH_USER,
  REMOVE_SLACK_AUTHENTICATION,
  FETCH_TEAMS,
  UPDATE_USER,
  FETCH_USER_BEERS,
  FETCH_CATALOGS,
  FETCH_USER_ADDRESS,
  CREATE_USER_ADDRESS,
  EDIT_USER_ADDRESS,
  DELETE_USER_ADDRESS,
  ORDER,
  CATALOG,
  ADDRESS,
  FETCH_TRANSACTION_HISTORY,
  FETCH_ORDERS,
  FETCH_MASTERDATA,
  CORE_VALUES
} from './routes';
import { API_BASE_URL } from '../globals';
import { dataHeaders } from '../components/utils';
import { Address, Catalog, NoteType } from '../library/Types';

export const login = async (params: { code?: string , error?: string}) => {
  return POST(`${API_BASE_URL}${LOGIN}`, params, false);
};

export const fetchUsers = async () => {
  return GET(`${API_BASE_URL}${FETCH_USERS}`);
};

export const cheers = async (params: any) => {
  return POST(`${API_BASE_URL}${CHEERS}`, params);
};

export const fetchCheersFeed = async (params?: any) => {
  return GET(`${API_BASE_URL}${FETCH_CHEERS_FEED}`, params);
};

export const cheersFeedReact = async (params: any) => {
  return POST(`${API_BASE_URL}${CHEERS_FEED_REACT}`, params);
};

export const fetchActivity = async (params?: any) => {
  return GET(`${API_BASE_URL}${FETCH_ACTIVITIES}`, params);
};

export const pollReactions = async (params?: any) => {
  return GET(`${API_BASE_URL}${POLL_REACTIONS}`, params);
};

export const fetchInsights = async (params?: any) => {
  return GET(`${API_BASE_URL}${FETCH_INSIGHTS}`, params);
};

export const fetchRank = async () => {
  return GET(`${API_BASE_URL}${FETCH_RANK}`);
};

export const fetchFAQs = async () => {
  return GET(`${API_BASE_URL}${FETCH_FAQS}`);
};

export const fetchLeaderBoard = async () => {
  return GET(`${API_BASE_URL}${FETCH_LEADER_BOARD}`);
};

export const oAuthStart = async (referrer: string) => {
  return GET(`${API_BASE_URL}${'/oauth/start'}?referrer=${referrer}`);
};

export const fileUploadOnS3 = async (body: any) => {
  return POST(`${API_BASE_URL}${GET_S3_UPLOAD_LINKS}`, body);
};

export const uploadProfilePic = async (body: { s3Url: string }) => {
  return POST(`${API_BASE_URL}${UPLOAD_PROFILE_PIC}`, body);
};

export const uploadImageToS3 = async (
  file: File,
  url: string,
  extension: string
) => {
  return PUT(url, file, {
    'Content-Type': dataHeaders[extension.toUpperCase()]
  });
};

export const fetchUser = async () => {
  return GET(`${API_BASE_URL}${FETCH_USER}`);
};

export const fetchUserBeers = async () => {
  return GET(`${API_BASE_URL}${FETCH_USER_BEERS}`);
};

export const removeSlackAuthentication = async() => {
  return POST(`${API_BASE_URL}${REMOVE_SLACK_AUTHENTICATION}`);
};

export const updateUser = async (body: any) => {
  return PATCH(`${API_BASE_URL}${UPDATE_USER}`, body);
};

export const fetchTeams = async () => {
  return GET(`${API_BASE_URL}${FETCH_TEAMS}`);
};

export const fetchCatalogs = async () => {
  return GET(`${API_BASE_URL}${FETCH_CATALOGS}`);
}

export const fetchUserAddress = async () => {
  return GET(`${API_BASE_URL}${FETCH_USER_ADDRESS}`);
}

export const createUserAddress = async (body: Address) => {
  return POST(`${API_BASE_URL}${CREATE_USER_ADDRESS}`, body)
}

export const updateUserAddress = async (body: Address, addressId: number) => {
  return PATCH(`${API_BASE_URL}${EDIT_USER_ADDRESS}/${addressId}`, body)
}

export const deleteUserAddress = async (addressId: number | undefined) => {
  return DELETE(`${API_BASE_URL}${DELETE_USER_ADDRESS}/${addressId}`);
}

export const createOrder = async (body: {note?: NoteType}, catalogId: number, addressId: number) => {
  return POST(`${API_BASE_URL}${ORDER}${CATALOG}/${catalogId}${ADDRESS}/${addressId}`, body);
}

export const fetchTransactionHistory = async (params?: any) => {
  return GET(`${API_BASE_URL}${FETCH_TRANSACTION_HISTORY}`, params);
}

export const updateOrder = async (body: {status: string, note?: NoteType}, orderId:number) => {
  return PATCH(`${API_BASE_URL}${ORDER}/${orderId}`, body);
}

export const createCatalog = async (body: Catalog) => {
  return POST(`${API_BASE_URL}${CATALOG}`, body);
}

export const updateCatalog = async (body: Catalog, catalogId?: number) => {
  return PATCH(`${API_BASE_URL}${CATALOG}/${catalogId}`, body)
}

export const deleteCatalog = async (catalogId?: number) => {
  return DELETE(`${API_BASE_URL}${CATALOG}/${catalogId}`);
}

export const fetchOrders = async () => {
  return GET(`${API_BASE_URL}${FETCH_ORDERS}`);
}

export const fetchMasterData = async () => {
  return GET(`${API_BASE_URL}${FETCH_MASTERDATA}`);
}

export const fetchCheerCoreValues = async () => {
  return GET(`${API_BASE_URL}${CORE_VALUES}`)
}
