import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

// import rootReducer from '../reducers';
const rootReducer = require('../components').default;

// create the saga middleware
const sagaMiddleware = createSagaMiddleware({
  onError: error => {
    console.error(error);
  }
});

const configureStore = (preloadedState: any) => {
  const devTools =
    ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
    ((a: any) => a);
  const store = createStore(
    rootReducer,
    preloadedState,
    compose(applyMiddleware(thunk), applyMiddleware(sagaMiddleware), devTools)
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../components', () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
};

export default configureStore;
