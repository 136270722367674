import React, { useEffect } from 'react';
import withStyles from 'react-jss';
import { Address, RouterProps } from '../library/Types'; // eslint-disable-line
import { Modal, Button, Input, Select, message, Form } from 'antd';
import { useGenericState } from '../library/UseGenericState';
import BackButton from '../cheerIcons/BackButton';
import { styles } from '../styles/createaddressmodal.styles';
import { createUserAddress, updateUserAddress, fetchMasterData } from '../api/apis';
import AddressForm from './AddressForm';
import { ActionTypes } from './utils';
import { connect } from 'react-redux';
interface State {
  isCreatingAddress: boolean;
  isUpdatingAddress: boolean;
}

const CreateAddressModal = (props: any): React.ReactElement => {

  const { classes, handleClose, isVisible, fetchUserAddressData, states, action, address } = props;
  const [state, setState] = useGenericState<State>({
    isCreatingAddress: false,
    isUpdatingAddress: false
  });
  const { isCreatingAddress, isUpdatingAddress } = state;

  const handleSaveAddress = async (body: Address) => {
    setState({ isCreatingAddress: true });
    const response = await createUserAddress(body);
    if(response.isSuccess){
      handleClose();
      message.success('Address Saved Successfully!');
      fetchUserAddressData();
    } else {
      message.error(response.errorMessage)
    }
    setState({ isCreatingAddress: false});
  }

  const handleUpdateAddress = async (body: Address) => {
    setState({ isUpdatingAddress: true });
    const response = await updateUserAddress(body, address.id);
    if(response.isSuccess){
      handleClose();
      message.success('Address Updated Successfully!');
      fetchUserAddressData();
    } else {
      message.error(response.errorMessage)
    }
    setState({ isUpdatingAddress: false});
  }

  const renderSaveButton = () => {
    return (
      <Button className={classes.button} loading={isCreatingAddress || isUpdatingAddress} form='addressForm' htmlType="submit">
        <span className={classes.save}>{action === ActionTypes.CREATE ? 'Save' : 'Update'}</span>
      </Button>
    );
  }

  return (
    <Modal
      className={classes.modal}
      visible={isVisible}
      onCancel={handleClose}
      closable={false}
      footer={null}
    >
      <div className={classes.parent}>
        <div className={classes.header}>
          <div className={classes.leftHeader}>
            <div className={classes.back} onClick={handleClose}><BackButton/></div>
            <div className={classes.title}>{action === ActionTypes.CREATE ? 'Add New' : 'Edit'} Address</div>
          </div>
          {renderSaveButton()}
        </div>
        <div className={classes.body}>
          <AddressForm classes={classes} handleSaveAddress={handleSaveAddress} handleUpdateAddress={handleUpdateAddress} action={action} address={address} states={states}/>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({ loginReducer }: any, ownProps: any) => ({
  states: loginReducer?.masterdata?.states
});

export default connect(mapStateToProps)(withStyles(styles)(CreateAddressModal));