import * as React from 'react';
import withStyles from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchMasterData, fetchUserBeers } from '../api/apis';
import { setMasterData, setUserBeers, setUserDetails } from './Login.actions';
import Navbar from './Navbar';
import { styles } from '../styles/merchandise.styles';
import { message } from 'antd';
import MerchandiseSection from './MerchandiseSection';
import * as crypto from 'crypto';

const Merchandise = (props: any) => {
  const { history, redeemableBeers, setUserBeers, setMasterData, userDetails } = props;

  React.useEffect(() => {
    fetchUserBeersData();
    getMasterData();
  }, []);

  const fetchUserBeersData = async () => {
    const response = await fetchUserBeers();
    if (response.isSuccess) {
      const { redeemableBeers } = response.data;
      const decipher = crypto.createDecipher('aes256', userDetails.id);
      let decrypted = '0';
      try{
        decrypted = decipher.update(redeemableBeers, 'hex', 'utf8') + decipher.final('utf8');
      }
      catch(err) {}
      setUserBeers(decrypted);
    } else {
      message.error(response.errorMessage);
    }
  }

  const getMasterData = async () => {
    const response = await fetchMasterData();
    if(response.isSuccess){;
      setMasterData(response.data)
    }
  }

  return (
    <div style={{ minWidth: 1200 }}>
      <Navbar history={history} />
      <MerchandiseSection redeemableBeers={redeemableBeers} history={history}/>
    </div>
  );
}

const mapStateToProps = ({ loginReducer }: any, ownProps: any) => ({
  userDetails: loginReducer?.userDetails,
  redeemableBeers: loginReducer?.redeemableBeers,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      setUserDetails,
      setUserBeers,
      setMasterData
    },
    dispatch
  );

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(Merchandise));