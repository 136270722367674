export const LOGIN = '/slack-oauth/callback';
export const FETCH_USERS = '/users';
export const CHEERS = '/cheers';
export const FETCH_CHEERS_FEED = '/cheers-feed';
export const CHEERS_FEED_REACT = '/cheers-feed/react';
export const FETCH_ACTIVITIES = '/users/my-activity';
export const FETCH_FAQS = '/faq';
export const POLL_REACTIONS = '/cheers-feed/reactions/poll';
export const FETCH_INSIGHTS = '/cheers/insights';
export const FETCH_RANK = '/users/rank';
export const FETCH_LEADER_BOARD = '/cheers/leader-board';
export const GET_S3_UPLOAD_LINKS = '/upload-file';
export const UPLOAD_PROFILE_PIC = '/users/upload/profile-pic';
export const FETCH_USER = '/user';
export const FETCH_USER_BEERS = '/user/beers';
export const REMOVE_SLACK_AUTHENTICATION = '/user/revoke-slack-token';
export const DELETE_USER = '/users';
export const FETCH_TEAMS = '/teams';
export const UPDATE_USER = '/user';
export const FETCH_CATALOGS = '/catalogs';
export const FETCH_USER_ADDRESS = '/user/address';
export const CREATE_USER_ADDRESS = '/user/address';
export const EDIT_USER_ADDRESS = '/user/address';
export const DELETE_USER_ADDRESS = '/user/address';
export const ORDER = '/order';
export const FETCH_ORDERS = '/orders';
export const CATALOG = '/catalog';
export const ADDRESS = '/address';
export const FETCH_TRANSACTION_HISTORY = '/user/transactions';
export const FETCH_MASTERDATA = '/merchandise/masterdata';
export const CORE_VALUES = '/cheers/tags';
