import * as React from 'react';
import withStyles from 'react-jss';
import { Catalog, RouterProps } from '../library/Types'; // eslint-disable-line
import { Modal, Button, message} from 'antd'; 
import {ActionTypes, TransactionStatusType } from './utils';
import { useGenericState } from '../library/UseGenericState';
import { deleteCatalog, updateCatalog } from '../api/apis';


interface IProps extends RouterProps {
  classes: any;
  handleClose: (isSucess?:boolean) => void;
  isVisible: boolean;
  card: any;
  adminUpdateOrder: (body: any, orderId: number, card: any, toLaneId: any) => void;
  isUpdatingOrder: boolean;
}

const styles = {
  modal: {
    '& .ant-modal-content': {
      borderRadius: '8px',
      width: 'fit-content',
      height: '250px',
      boxShadow: '2px 2px 8px 0 rgba(0,0,0,0.09)'
    },
    '& .ant-modal-body': {
      padding: '9px 16px',
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'space-around'
    }
  },
  removeButton: {
    color: '#111111',
    backgroundColor: '#FFD17B',
    border: '1px solid #111111',
    marginRight: '16px',
    '&:focus': {
      color: '#111111',
      backgroundColor: '#FFD17B',
      border: '1px solid #111111'
    },
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#111111',
      border: '1px solid #111111'
    }
  },
  cancelButton:{
    color: '#111111',
    '&:hover': {
        color: '#111111',
    }
  },
  text: {
    color: '#111111',
    fontSize: '18px',
    letterSpacing: 0,
    lineHeight: '19px',
    marginBottom: '4px'
  },
  head: {
    color: '#111111',
    fontSize: '24px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '18px'
      
  },
  hr:{
    height: '1px',
    width: '400px',
    transform: 'scaleY(-1)',
    backgroundColor: '#EDEDED'
  },
  buttonWrapper:{
    display: 'flex'
  }
};

const DeliveredOrderModal = (props: IProps): React.ReactElement => {
  const { classes, handleClose, isVisible, card, adminUpdateOrder, isUpdatingOrder } = props;

  const handleDeliverOrder = async () => {
    adminUpdateOrder({ status: TransactionStatusType.DELIVERED}, card.id, card, 4);
  }

  return (
    <Modal
      className={classes.modal}
      visible={isVisible}
      onCancel={() => handleClose()}
      closable={false}
      footer={null}
      centered
    > 
        <div className={classes.head}>Deliver Catalog</div>
        <div>
            <p className={classes.text}>Are you sure you want to mark this order delivered?</p>
            <p style={{marginBottom: '0px', position: 'relative', top: '20px'}}>Note: This action cannot be undone</p>
        </div>
        
        <div className={classes.hr}></div>
        <div className={classes.buttonWrapper}>
            <Button className={classes.removeButton} loading={isUpdatingOrder} onClick={handleDeliverOrder}>Mark Delivered!</Button>
            <Button onClick={() => handleClose()} className={classes.cancelButton}>Cancel</Button>  
        </div>
    </Modal>
  );
};

export default withStyles(styles)(DeliveredOrderModal);