export const styles = {
  parentDiv: {
    minWidth: 1200,
    '& .ant-tabs-bar':{
      borderBottom: 'none !important',
      margin: '0 !important'
    },
    '& .ant-tabs-nav':{
      height: '48px',
      '&:hover': {
        color: '#111111'
      }
    },
    '& .ant-tabs-nav-container':{
      padding: '0px 16px',
      marginBottom: '1px'
    },
    '& .ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane, .ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane':{
      backgroundColor: '#E6E2DB'
    },
    '& .sc-jRQBWg':{
      height: 'calc(100vh - 108px)',
    },
    '& .ant-tabs-ink-bar': {
      height: '4px',
      backgroundColor: '#111111',
      width: 'auto'
    },
    '& .ant-tabs-nav .ant-tabs-tab-active': {
      color: '#111111 !important',
    },
    '& .ant-tabs-nav .ant-tabs-tab:hover':{
      color: '#111111 !important'
    },
    '& .smooth-dnd-container.horizontal':{
      display: 'flex',
      width: 'calc(100vw - 10px)',
      height: 'calc(100vh - 50px)',
      justifyContent: 'space-evenly'
    },
    '& .sc-iCfMLu':{
      height: 'fit-content',
      width: '20vw',
    },
    '& .sc-jrQzAO': {
      minWidth: '100%',
      width: '100%',
      paddingRight: '1px'
    }
  },
  navbar:{
    display: 'flex',
    height: '60px',
    alignItems: 'center',
    padding: '0px 16px'
  },
  option:{
    height: '48px',
    backgroundColor: '#FFF9E5',
  },
  manageCatalogWrapper:{
    padding: '16px'
  },
  catalogCard: {
    boxSizing: 'border-box',
    height: '576px',
    maxWidth: '450px',
    border: '1px solid #FCFCFC',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    padding: 24
  },
  images:{
    height: '386px',
    background: 'lightpink'
  },
  button: {
    height: '32px',
    width: '81px',
    borderRadius: '6px',
    backgroundColor: '#111111',
    color: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:focus': {
        color: '#FFFFFF',
        backgroundColor: 'rgba(1,1,1,0.8)',
        border: '1px solid #CCCCCC'
    },
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: 'rgba(1,1,1,0.8)',
        border: '1px solid #CCCCCC'
    },
  },
  buyNow: {
      color: '#FFFFFF',
      fontSize: '12px',
      letterSpacing: 1,
      lineHeight: '20px',
      textAlign: 'center',
  },
  title:{
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '52px',
    color: '#121212',
  },
  description: {
    fontSize: '12px',
    color: '#111111'
  },
  purchaseWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px'
  },
  price: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#121212',
  },
  backButton: {
    marginRight: '12px', 
    cursor: 'pointer',
    display: 'flex'
  }
}