export const getFileExtension = (filename: string) => {
  const ext = /^.+\.([^.]+)$/.exec(filename);
  return ext == null ? '' : ext[1];
};

export const dataHeaders: Record<string, string> = {
  JPG: 'image/*',
  JPEG: 'image/*',
  PNG: 'image/*',
  PDF: 'application/*',
  DOCX: 'application/*',
  XLSX: 'application/*',
  CSV: 'text/csv',
  XLS: 'application/*',
  DOC: 'application/*'
};

export enum ToastType {
  SUCCESS = 'success',
  ERROR = 'error'
}

export enum RolesType {
  ADMIN = 'Admin',
  MEMBER = 'Member'
}

export enum ActionTypes {
  CREATE = 'Create',
  UPDATE = 'Update',
  ACTIVATE = 'Activate',
  DEACTIVATE = 'Deactivate',
  DELETE = 'Delete'
}


export enum TransactionStatusType {
  NEW_ORDER = "New Order",
  ACCEPTED = "Accepted",
  ORDER_PLACED = "Order Placed",
  DELIVERED = "Delivered",
  CANCELLED = "Cancelled",
  REJECTED = "Rejected"
}

export enum TransactionEventType {
  ORDER = "Order",
  MONTHLY_CHILLLER_STORAGE = "Monthly_Chiller_Storage",
  REFUND = "Refund",
  INITIAL_CHILLER_STORAGE = "Initial_Chiller_Storage",
  REWARD = "Reward"
}

export enum TransactionType {
  DEBIT = "Debit",
  CREDIT = "Credit"
}

export const TransactionStatusColor:any = {
  'New Order' : {
    color: '#603417',
    backgroundColor: '#FFF3B0'
  },
  'Accepted' : {
    color: '#174060',
    backgroundColor: '#CFF1FF'
  },
  'Order Placed' : {
    color: '#603417',
    backgroundColor: '#FFF3B0'
  },
  'Delivered' : {
    color: '#176037',
    backgroundColor: '#CFFFD2'
  },
  'Rejected' : {
    color: '#CA2E2E',
    backgroundColor: '#FFF1F1'
  },
  'Cancelled' : {
    color: '#CA2E2E',
    backgroundColor: '#FFF1F1'
  }
}