import * as React from 'react';

export default () => {
  return (
    <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>noun_ban_4287593 copy</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="noun_ban_4287593-copy" fill="#000000" fill-rule="nonzero">
                <path d="M7,0 C3.14015,0 0,3.14015 0,7 C0,10.85985 3.14015,14 7,14 C10.85985,14 14,10.85985 14,7 C14,3.14015 10.85985,0 7,0 Z M1,7 C1,5.52545 1.537,4.17555 2.4227,3.12975 L10.87025,11.5773 C9.82445,12.463 8.47455,13 7,13 C3.69165,13 1,10.30835 1,7 Z M11.5773,10.87025 L3.12975,2.4227 C4.17555,1.537 5.52545,1 7,1 C10.30835,1 13,3.69165 13,7 C13,8.47455 12.463,9.82445 11.5773,10.87025 Z" id="Shape"></path>
            </g>
        </g>
    </svg>
  );
}