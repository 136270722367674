import * as React from 'react';
import withStyles from 'react-jss';
import { RouterProps } from '../library/Types'; // eslint-disable-line
import { Modal, Button } from 'antd';
import SuccessGif from '../cheerIcons/Success.gif';
import { useGenericState } from '../library/UseGenericState';
import { useHistory } from 'react-router';
import { getDashboardRoute } from '../routing/utils';

interface IProps extends RouterProps {
  classes: any;
  handleClose: () => void;
  isVisible: boolean;
}

const styles = {
  modal: {
    minWidth: '920px',
    '& .ant-modal-content': {
      borderRadius: '16px'
    },
    '& .ant-modal-body': {
      padding: '9px 11px',
      textAlign: 'center',
      backgroundColor: '#F8C13B',
      borderRadius: '16px',
      display: 'flex',
      alignItems: 'center'
    }
  },
  button: {
    color: '#111111',
    backgroundColor: '#FFD17B',
    border: '1px solid #111111',
    '&:focus': {
      color: '#111111',
      backgroundColor: '#FFD17B',
      border: '1px solid #111111'
    },
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#111111',
      border: '1px solid #111111'
    },
    padding: '0 36px',
    height: '48px'
  }
};

const OrderSuccessModal = (props: IProps): React.ReactElement => {
  const { classes, handleClose, isVisible } = props;
  let history = useHistory();

  return (
    <Modal
      className={classes.modal}
      visible={isVisible}
      onCancel={handleClose}
      closable={false}
      footer={null}
    >
      <img src={SuccessGif} alt="" style={{ maxWidth: '466px' }} />
      <div style={{ textAlign: 'left' }}>
        <div
          style={{
            color: '#111111',
            fontWeight: 'bold',
            fontSize: '36px',
            marginTop: '32px'
          }}
        >
          Thank you for Shopping!
        </div>
        <div
          style={{
            color: '#161616',
            fontSize: '18px',
            marginTop: '10px'
          }}
        >
          Your order has been placed successfully.
          <br />
          Keep Cheering! Keep Shopping!
        </div>
        <div style={{ display: 'flex', alignItems: 'center', padding: '40px 0px'}}>
          <Button
            style={{
              fontSize: '14px',
              marginRight: '18px'
            }}
            className={classes.button}
            onClick={() => history.push(getDashboardRoute())}
          >
            Wokay!
          </Button>
          <div style={{cursor: 'pointer', fontSize: '14px', color: '#111111'}} onClick={handleClose}>Shop More</div>
        </div>
      </div>
    </Modal>
  );
};

export default withStyles(styles)(OrderSuccessModal);
