import * as React from 'react';
import withStyles from 'react-jss';
import { Address, RouterProps } from '../library/Types'; // eslint-disable-line
import { Modal, Button, message, Form} from 'antd'; 
import {ToastType, TransactionStatusType} from './utils';
import { useGenericState } from '../library/UseGenericState';
import Loader from '../cheerIcons/Loader.gif';
import { deleteUserAddress } from '../api/apis';
import BackButton from '../cheerIcons/BackButton';
import TextArea from 'antd/lib/input/TextArea';
import { FormComponentProps } from 'antd/lib/form';
import { styles } from '../styles/rejectordermodal.styles';

interface IProps extends FormComponentProps<any>{
  classes: any
  handleClose: () => void;
  isVisible: boolean;
  card: any;
  adminUpdateOrder: (body: any, orderId: number, card: any, toLaneId: any) => void;
  isUpdatingOrder: boolean;
}

const RejectOrderModal = (props: IProps): React.ReactElement => {
  const { classes, handleClose, isVisible, form, card, adminUpdateOrder, isUpdatingOrder } = props;
  const { getFieldDecorator } = form;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if(!err){
        const rejectReason = form.getFieldValue('reject reason');
        const body = {
          status: TransactionStatusType.REJECTED,
          note: {...card.note, admin: rejectReason}
        }
        adminUpdateOrder(body, card.id, card, 5);
      }
    })
  }

  return (
    <Modal
      className={classes.modal}
      visible={isVisible}
      onCancel={() => handleClose()}
      closable={false}
      footer={null}
      centered
    > 
        <div className={classes.parent}>
        <div className={classes.header}>
          <div className={classes.leftHeader}>
            <div className={classes.modalBack} onClick={handleClose}><BackButton/></div>
            <div className={classes.modalTitle}>Rejecting Order</div>
          </div>
          <Button className={classes.rejectModalButton} form='rejectForm' htmlType="submit" loading={isUpdatingOrder}>
            <span className={classes.save}>Reject</span>
          </Button>
        </div>
        <div className={classes.body}>
          <div className={classes.orderDetails}>
            <div className={classes.orderDetailsTitle}>Order Details</div>
            <div className={classes.catalogDetailsWrapper}>
              <div className={classes.catalogImage}>
                <img src={card.image_urls[0]} className={classes.rejectCatalogImage}/>
              </div>
              <div className={classes.catalogTitle}>{card.title}</div>
            </div>
          </div>
          <Form id='rejectForm' layout='vertical' onSubmit={handleSubmit}>
            <Form.Item label='Reason of rejecting the order'>
            {getFieldDecorator('reject reason', {
                rules: [{ required: true, message: 'Please input your reason!' }],
              })(
                <TextArea
                  placeholder="Write a reason"
                  autoSize={{ minRows: 3, maxRows: 3 }}
                />
              )}
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default Form.create<IProps>()(withStyles(styles)(RejectOrderModal));

