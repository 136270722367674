import * as React from 'react';
import { Switch, Route } from 'react-router';
import { getAdminRoute, getDashboardRoute, getMerchandiseRoute } from './utils';
import Loader from '../cheerIcons/Loader.gif';
import Merchandise from '../components/Merchandise';
import Manage from '../components/Manage';

const Dashboard = (React as any).lazy(() => import('../components/Dashboard'));
const DefaultPage = React.lazy(() => import('../components/DefaultPage'));
const Suspense = React.Suspense;

const RouterOutlet = (props: any) => {
  return (
    <Suspense
      fallback={
        <div style={{ margin: '28% 0 0 50%' }}>
          <img src={Loader} alt="" />
        </div>
      }
    >
      <Switch>
        <Route path={getDashboardRoute()} exact component={Dashboard} />
        <Route path={getMerchandiseRoute()} exact component={Merchandise} />
        <Route path={getAdminRoute()} exact component={Manage} />
        <Route component={DefaultPage} />
      </Switch>
    </Suspense>
  );
};

export default RouterOutlet;
