export const styles = {
  parentDiv: {
    backgroundColor: '#E6E2DB',
    padding: '15px 1.5vw'
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    background: 'white',
    height: '64px',
    alignItems: 'center',
    borderRadius: '4px',
    padding: '0px 16px'
  },
  leftFlex:{
    fontSize: '18px',
    display: 'flex',
    alignItems: 'center'
  },
  rightFlex:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  beerValue: {
    color: '#111111',
    fontSize: '18px',
    fontWeight: 'bold',
    letterSpacing: '0',
    lineHeight: '18px',
    textAlign: 'right',
    marginBottom: '4px'
  },
  beerTitle: {
    color: '#333333',
    fontSize: '10px',
    letterSpacing: 0,
    lineHeight: '10px',
  }
}