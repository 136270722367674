import * as React from 'react';

export default (props: any) => {
  const {height} = props;
  return (
    <svg width="24px" height={height || '24px'} viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" >
        <title>Cross</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Cross">
                <rect id="Rectangle-36-Copy" x="0" y="0" width="24" height="24"></rect>
                <path d="M13.3054688,11.9976563 L19.2773438,6.03046875 C19.6382813,5.66953125 19.6382813,5.0859375 19.2773438,4.72734375 C18.9164063,4.36640625 18.3328125,4.36640625 17.9742188,4.72734375 L12,10.6921875 L6.02578125,4.725 C5.66484375,4.3640625 5.08125,4.3640625 4.72265625,4.725 C4.36171875,5.0859375 4.36171875,5.66953125 4.72265625,6.028125 L10.6945313,11.9953125 L4.72265625,17.9648438 C4.36171875,18.3257813 4.36171875,18.909375 4.72265625,19.2679688 C4.903125,19.4484375 5.13984375,19.5375 5.37421875,19.5375 C5.6109375,19.5375 5.8453125,19.4484375 6.02578125,19.2679688 L12,13.3007813 L17.9742188,19.2703125 C18.1546875,19.4507813 18.3890625,19.5398438 18.6257813,19.5398438 C18.8625,19.5398438 19.096875,19.4507813 19.2773438,19.2703125 C19.6382813,18.909375 19.6382813,18.3257813 19.2773438,17.9671875 L13.3054688,11.9976563 Z" id="Shape" fill="#333333" fill-rule="nonzero"></path>
            </g>
        </g>
    </svg>
  );
}