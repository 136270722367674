import {
  SET_USER_DETAILS,
  IS_FETCHING_FEED,
  SET_FEED_DATA,
  SET_FEED_DATA_ERROR,
  SET_FILTERS,
  SET_ACTIVITY_LIST,
  SET_ACTIVITY_LIST_ERROR,
  IS_FETCHING_ACTIVITY,
  FORCE_LOGOUT,
  SET_USER_BEERS,
  SET_CATALOG_DETAILS,
  SET_MASTER_DATA
} from './action.constants';

const defaultState: any = {
  userDetails: {
    name: localStorage.getItem('name'),
    email: localStorage.getItem('email'),
    profilePicUrl: localStorage.getItem('profilePicUrl'),
    id: localStorage.getItem('id'),
    wallet: localStorage.getItem('wallet'),
    teamId: localStorage.getItem('teamId')
  },
  isFetchingFeed: true,
  activityCurrentPageNumber: 1,
  feedCurrentPageNumber: 1,
  isFetchingActivity: true,
  forceLogout: false
};
export default (state = defaultState, action: any) => {
  // eslint-disable-line
  switch (action.type) {
    case SET_USER_DETAILS: {
      return {
        ...state,
        userDetails: action.data
      };
    }

    case IS_FETCHING_FEED: {
      return {
        ...state,
        isFetchingFeed: action.data,
        isFetchingFeedFirstTime: action.firstTime
      };
    }

    case SET_FEED_DATA: {
      return {
        ...state,
        feedData: action.firstTime
          ? action.data
          : [...(state.feedData || []), ...action.data],
        feedDataError: '',
        feedCurrentPageNumber: action.firstTime
          ? 1
          : state.feedCurrentPageNumber + 1
      };
    }

    case SET_FEED_DATA_ERROR: {
      return {
        ...state,
        feedDataError: action.data
      };
    }

    case SET_FILTERS: {
      return {
        ...state,
        searchFilters: {
          ...state.searchFilters,
          ...action.data
        }
      };
    }

    case SET_ACTIVITY_LIST_ERROR: {
      return {
        ...state,
        activityError: action.data
      };
    }

    case SET_ACTIVITY_LIST: {
      return {
        ...state,
        activityList: action.firstTime
          ? action.data
          : [...(state.activityList || []), ...action.data],
        activityError: '',
        activityCurrentPageNumber: action.firstTime
          ? 1
          : state.activityCurrentPageNumber + 1
      };
    }

    case IS_FETCHING_ACTIVITY: {
      return {
        ...state,
        isFetchingActivity: action.data,
        isFetchingActivityFirstTime: action.firstTime
      };
    }

    case FORCE_LOGOUT: {
      return {
        ...state,
        forceLogout: action.data
      };
    }

    case SET_USER_BEERS: {
      return {
        ...state,
        redeemableBeers: action.data
      }
    }

    case SET_CATALOG_DETAILS: {
      return {
        ...state,
        catalogs: action.data
      }
    }

    case SET_MASTER_DATA:{
      return {
        ...state,
        masterdata: action.data
      }
    }

    default:
      return state;
  }
};
