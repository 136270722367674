import * as React from 'react';
import withStyles from 'react-jss';
import useOutsideClick from '../hooks/useOutsideClick';
import { getLogoutRoute } from '../routing/utils';
import { Icon, Upload, message, Spin } from 'antd';
import { Redirect } from 'react-router';
import user from '../cheerIcons/user.png';
import { getFileExtension } from './utils';
import { fileUploadOnS3, uploadImageToS3, uploadProfilePic } from '../api/apis';

const styles = {
  mainDiv: {
    marginTop: '12%',
    background: '#FFFFFF',
    position: 'absolute',
    zIndex: 10,
    width: 'auto',
    padding: '16px 24px',
    border: '1px solid #FCFCFC',
    boxShadow: '2px 2px 8px 0 rgba(0,0,0,0.09)',
    borderRadius: '4px',
    right: '24px'
  },
  logout: {
    color: '#DF5353',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },
  slackIntegration: {
    color: '#333333',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '18px',
    marginTop: '-7px',
  },
  changePic: {
    color: '#333333',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '23px'
  },
  spin: {
    '& .ant-spin-dot-item': {
      backgroundColor: '#FFD17B'
    },
    marginLeft: '6px'
  }
};

interface IProps {
  classes: any;
  handleClickOutside: () => void;
  history: any;
  userDetails: any;
}

const LogoutPopup = (props: IProps): React.ReactElement => {
  const { classes, handleClickOutside } = props;

  const [loggedOut, setLoggedOut] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);

  const node: any = React.useRef();

  useOutsideClick(node, () => {
    handleClickOutside();
  });

  const handleLogout = (): void => {
    setLoggedOut(true);
  };

  if (loggedOut) {
    return <Redirect to={{ pathname: getLogoutRoute() }} />;
  }

  const handleChangePicture = async ({ file }: { file: any }) => {
    if (uploading) {
      return;
    }
    setUploading(true);
    const extension = getFileExtension(file.name);
    const response = await fileUploadOnS3({
      extension,
      file: file.name,
      document_type: file.type,
      initialPath: 'beer-for-cheer'
    });
    if (response.isSuccess) {
      const upload = await uploadImageToS3(file, response.data.post, extension);
      if (upload.isSuccess) {
        setUploading(false);
        const uploadPicResponse = await uploadProfilePic({
          s3Url: response.data.get
        });
        if (uploadPicResponse?.isSuccess) {
          window.localStorage.setItem(
            'profilePicUrl',
            response.data.get
          );
          handleClickOutside();
          window.location.reload();
        } else {
          message.error(uploadPicResponse.errorMessage);
          setUploading(false);
        }
      } else {
        message.error(upload.errorMessage);
        setUploading(false);
      }
    } else {
      message.error(response.errorMessage);
      setUploading(false);
    }
  };

  return (
    <div className={classes.mainDiv} ref={node}>
      <Upload
        accept={'.jpg, .jpeg, .png'}
        className={classes.upload}
        showUploadList={false}
        multiple={false}
        customRequest={file => handleChangePicture(file)}
      >
        <span className={classes.changePic}>
          <img src={user} alt="" style={{ marginRight: '12px' }} />
          Change Display Picture {uploading && <Spin className={classes.spin} />}
        </span>
      </Upload>
      <span className={classes.logout} onClick={handleLogout}>
        <Icon
          type="poweroff"
          style={{ fontSize: '24px', marginRight: '12px' }}
        />
        Sign Out
      </span>
    </div>
  );
};

export default withStyles(styles)(LogoutPopup);
