import * as React from 'react';

export default () => {
  return (
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Edit</title>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Edit">
              <rect id="Rectangle-44" x="0" y="0" width="16" height="16"></rect>
              <path d="M14.4375,13.875 L1.5625,13.875 C1.2515625,13.875 1,14.1265625 1,14.4375 C1,14.7484375 1.2515625,15 1.5625,15 L14.4375,15 C14.7484375,15 15,14.7484375 15,14.4375 C15,14.1265625 14.7484375,13.875 14.4375,13.875 Z M4.625,12.03125 L8.3046875,10.4734375 C8.3640625,10.4484375 8.4171875,10.4125 8.4640625,10.3671875 L14.6703125,4.1609375 C15.109375,3.721875 15.1125,3.00625 14.6734375,2.5671875 L13.434375,1.328125 C12.9953125,0.8890625 12.2796875,0.8921875 11.840625,1.33125 L5.634375,7.5375 C5.5890625,7.5828125 5.553125,7.6375 5.528125,7.696875 L3.96875,11.375 C3.88125,11.58125 3.9421875,11.7890625 4.075,11.9234375 C4.2078125,12.0578125 4.4171875,12.11875 4.625,12.03125 Z M12.6359375,2.1265625 L12.6390625,2.1234375 L13.8765625,3.3609375 L13.8734375,3.3640625 L12.9046875,4.3328125 L11.6671875,3.0953125 L12.6359375,2.1265625 Z M6.515625,8.246875 L10.871875,3.890625 L12.109375,5.128125 L7.753125,9.484375 L5.60625,10.39375 L6.515625,8.246875 Z" id="Shape" fill="#000000" fill-rule="nonzero"></path>
          </g>
      </g>
  </svg>
  );
};