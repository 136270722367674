import queryString from 'querystring';
import React, { useEffect, ReactElement } from 'react'; // eslint-disable-line
import url from 'url';
import { login } from '../api/apis';
import { getLoginRoute, getDashboardRoute } from '../routing/utils';
import Loader from '../cheerIcons/Loader.gif';
import { Redirect, RouterProps } from 'react-router'; // eslint-disable-line
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setUserDetails } from './Login.actions';

interface IProps extends RouterProps {
  setUserDetails: (details: any) => void;
}

const GAuthPage = (props: IProps): ReactElement => {
  useEffect(() => {
    initialize();
  }, []); // eslint-disable-line

  const { setUserDetails, history } = props;

  const setStorage = (values: any) => {
    const { id, name, accessToken, profilePicUrl, wallet, teamId, email} = values;
    const storage = window.localStorage;
    storage.setItem('id', id);
    storage.setItem('name', name);
    storage.setItem('email',email);
    if(teamId) storage.setItem('teamId',teamId);
    if(accessToken) storage.setItem('authToken', accessToken);
    storage.setItem('profilePicUrl', profilePicUrl);
    storage.setItem('wallet', wallet);
    storage.setItem('lastActionTime', String(new Date()));
  };

  const initialize = async () => {
    const { location } = window;

    if (!location) {
      return history.push(getLoginRoute());
    }
    const { href = '' } = location;
    const { query } = url.parse(href);

    if (query) {
      const parsedQuery = queryString.parse(query);
      const { code, error = '' } = parsedQuery;
      const reqBody = {
        code: (code as string) || '',
        error: (error as string) || ''
      };

      const response = await login(reqBody);
      if (response.isSuccess) {
        setStorage({
          id: response.data?.id,
          name: response.data?.name,
          accessToken: response.data?.token,
          profilePicUrl: response.data?.profilePicUrl,
          wallet: response.data?.wallet,
          teamId: response.data?.teamId,
          email: response.data?.email
        });
        setUserDetails({ ...response.data });
        history.push(getDashboardRoute());
      } else {
        return history.replace(getLoginRoute(), {
          ...window.history.state,
          error: response.errorMessage
        });
      }
    }
  };

  return (
    <div style={{ margin: '15% 0 0 48%' }}>
      <img src={Loader} alt="" />
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      setUserDetails
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(GAuthPage);
