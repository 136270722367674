import { message, Spin, Tabs } from 'antd';
import * as React from 'react';
import withStyles from 'react-jss';
import { getAuthenticationHeaders } from '../api/utils';
import BackButton from '../cheerIcons/BackButton';
import { useGenericState } from '../library/UseGenericState';
import { getDashboardRoute } from '../routing/utils';
import jwt_decode from 'jwt-decode';
import { RolesType, TransactionStatusType } from './utils';
import { styles } from '../styles/manage.styles';
import { RouterProps } from '../library/Types';
import CatalogCardsWrapper from './CatalogCardsWrapper';
import { ADMIN_PAGE_NAVBAR_TABS_SPACE } from '../globals';
import KanbanCard from './KanbanCard';
import Board from 'react-trello';
import Loader from '../cheerIcons/Loader.gif';
import { fetchOrders, updateOrder } from '../api/apis';
import RejectOrderModal from './RejectOrderModal';
import { snakeCase } from 'snake-case';
import DeliveredOrderModal from './DeliveredOrderModal';

interface ManageProps extends RouterProps{
  classes : {
    [X in keyof (typeof styles)]: string;
  }
}

const Manage = (props: ManageProps) => {
  const { history , classes} = props;

  React.useEffect(() => {
    const decoded: any = jwt_decode(getAuthenticationHeaders()?.['access-token']);
    if(decoded?.role !== RolesType.ADMIN) history.push(getDashboardRoute());
    fetchOrdersData();  
  },[]);

  const laneIdToStatusMap = {
    1: {
      title: TransactionStatusType.NEW_ORDER,
      color: '#FFFFFF'
    },
    2: {
      title: TransactionStatusType.ACCEPTED,
      color: '#FFFBEF'
    },
    3: {
      title: TransactionStatusType.ORDER_PLACED,
      color: '#EAF5F8'
    },
    4: {
      title: TransactionStatusType.DELIVERED,
      color: '#F0F8E8'
    },
    5: {
      title: TransactionStatusType.REJECTED,
      color: '#F9EDEB'
    },
    6: {
      title: TransactionStatusType.CANCELLED,
      color: '#EDEDED'
    }
  }

  const statusToLaneIdMap = {}
  statusToLaneIdMap[TransactionStatusType.NEW_ORDER] = 1;
  statusToLaneIdMap[TransactionStatusType.ACCEPTED] = 2;
  statusToLaneIdMap[TransactionStatusType.ORDER_PLACED] = 3;
  statusToLaneIdMap[TransactionStatusType.DELIVERED] = 4;
  statusToLaneIdMap[TransactionStatusType.REJECTED] = 5;
  statusToLaneIdMap[TransactionStatusType.CANCELLED] = 6;

  const [state, setState] = useGenericState<any>({
    activeKey: "orders",
    orders: [],
    isFetchingOrders: false,
    isUpdatingOrder: false,
    isRejectOrderModalVisible: false,
    isDeliveredOrderModalVisible: false,
    activeCard: null
  });
  const { activeKey, orders, isFetchingOrders, isUpdatingOrder, isRejectOrderModalVisible, isDeliveredOrderModalVisible, activeCard } = state;

  const fetchOrdersData = async () => {
    setState({isFetchingOrders: true});
    let orders = [];
    const response = await fetchOrders();
    if(response.isSuccess){
      orders = response.data;
    } else {
      message.error(response.errorMessage);
    }
    setState({isFetchingOrders: false, orders});
  }

  const components = {
    Card: KanbanCard
  }

  let map = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: []
  }

  orders.forEach((order) => {
    order.color = laneIdToStatusMap[statusToLaneIdMap[order.status]].color
    map[statusToLaneIdMap[order.status]] = [...map[statusToLaneIdMap[order.status]], order];
  })

  const data = {
    lanes: [
      {
        id: '1',
        title: TransactionStatusType.NEW_ORDER,
        cards: map[1]
      },
      {
        id: '2',
        title: TransactionStatusType.ACCEPTED,
        cards: map[2]
      },
      {
        id: '3',
        title: TransactionStatusType.ORDER_PLACED,
        cards: map[3]
      },
      {
        id: '4',
        title: TransactionStatusType.DELIVERED,
        cards: map[4]
      },
      {
        id: '5',
        title: `${TransactionStatusType.REJECTED} / ${TransactionStatusType.CANCELLED}`,
        cards: [...map[5],...map[6]]
      }
    ]
  }

  const isRestricted = (fromLaneId: any, toLaneId: any) => {
    return ( fromLaneId >= toLaneId || laneIdToStatusMap[fromLaneId].title === TransactionStatusType.DELIVERED)
  }

  const onCardMoveAcrossLanes = async (fromLaneId: any, toLaneId: any, cardId: any) => {
    if(isRestricted(fromLaneId, toLaneId)) {
      setState({
        orders
      })
    }
    else {
      const [card] = orders.filter((item: any) => item.id === cardId);
      if(laneIdToStatusMap[toLaneId].title === TransactionStatusType.REJECTED){
        setState({ isRejectOrderModalVisible: true, activeCard: card });
      }
      else if(laneIdToStatusMap[toLaneId].title === TransactionStatusType.DELIVERED){
        setState({ isDeliveredOrderModalVisible: true, activeCard: card });
      }
      else{
        await adminUpdateOrder({ status: laneIdToStatusMap[toLaneId].title }, cardId, card, toLaneId);
      }     
    }
  }

  const adminUpdateOrder = async (body: any, orderId: number, card: any, toLaneId: any) => {
    setState({ isUpdatingOrder: true });
    const response = await updateOrder(body, orderId);
    if (response.isSuccess) {
      message.success(`Order ${body.status!==TransactionStatusType.ORDER_PLACED ? body.status : 'Placed'} Successfully!`);
      const index = orders.indexOf(card);
      if (index > -1) {
        orders.splice(index, 1);
      }
      card.status = laneIdToStatusMap[toLaneId].title
      card.color = laneIdToStatusMap[toLaneId].color;
      card[`${snakeCase(laneIdToStatusMap[toLaneId].title)}_at`] = new Date()
      setState({orders: [card, ...orders]});
    } else {
      setState({ orders });
      message.error(response.errorMessage);
    }
    setState({ isUpdatingOrder: false, isRejectOrderModalVisible: false, isDeliveredOrderModalVisible: false });
  }

  return (
    <div className={classes.parentDiv}>
      <div className={classes.navbar}>
        <div className={classes.backButton} onClick={() => history.push(getDashboardRoute())}><BackButton /></div>
        <div style={{ color: '#333333', fontSize: '16px'}}>Manage BFC</div>
      </div>
      <div className={classes.option}>
        <Tabs activeKey={activeKey} onChange={(key)=> setState({activeKey:key})}>
          <Tabs.TabPane tab="Orders" key="orders">
            { isFetchingOrders ? 
              <div style={{ backgroundColor: 'white', padding: '20% 0px 0px 45%'}}>
                <img src={Loader} alt="" style={{ height: '100px' }}/>
              </div> : 
              <Spin spinning={isUpdatingOrder} size='large'>
                <Board data={data} onCardMoveAcrossLanes={onCardMoveAcrossLanes} components={components} hideCardDeleteIcon/>
              </Spin>
            }
          </Tabs.TabPane>
          <Tabs.TabPane tab="Items" key="items">
          <div className={classes.manageCatalogWrapper} style={{minHeight: `calc(100vh - ${ADMIN_PAGE_NAVBAR_TABS_SPACE}px)`}}>
              <CatalogCardsWrapper isAdmin={true} />
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
      { isRejectOrderModalVisible && 
        <RejectOrderModal 
          classes={classes}
          isVisible={isRejectOrderModalVisible} 
          handleClose={() => setState({isRejectOrderModalVisible: false })}
          card={activeCard}
          adminUpdateOrder={adminUpdateOrder}
          isUpdatingOrder={isUpdatingOrder}
        />
      }
      { isDeliveredOrderModalVisible && 
        <DeliveredOrderModal 
          classes={classes}
          isVisible={isDeliveredOrderModalVisible} 
          handleClose={() => setState({isDeliveredOrderModalVisible: false })}
          card={activeCard}
          adminUpdateOrder={adminUpdateOrder}
          isUpdatingOrder={isUpdatingOrder}
        />
      }
    </div>
  );
}

export default withStyles(styles)(Manage)