import * as React from 'react';
import withStyles from 'react-jss';
import { MOBILE_BREAK_POINT } from '../globals';
import Logo from '../cheerIcons/logo.png';
import { connect } from 'react-redux';
import { useGenericState } from '../library/UseGenericState';
import LogoutPopup from './LogoutPopup';
import jwt_decode from 'jwt-decode';
import { getAuthenticationHeaders } from '../api/utils';
import { RolesType } from './utils';
import AdminManage from '../cheerIcons/AdminManage';
import { getAdminRoute } from '../routing/utils';

const styles = {
  navbar: {
    height: '56px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '9px 24px',
    border: '1px solid #CCCCCC',
    color: 'black',
    backgroundColor: '#FFFFFF'
  },
  leftFlex: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start'
  },
  [`@media (max-width: ${MOBILE_BREAK_POINT}px)`]: {
    navbar: {
      border: 'none',
      color: 'white',
      '& .ant-tabs-nav .ant-tabs-tab-active': {
        color: '#FFFFFF'
      }
    }
  },
  rightTools: {
    alignItems: 'center',
    display: 'flex'
  },
  toolContainer: {
    '&:not(:last-child)': {
      marginRight: '8px'
    }
  },
  profilePic: {
    borderRadius: '50%',
    height: '32px',
    width: '32px',
    marginLeft: '12px'
  }
};

const Navbar = (props: any) => {
  React.useEffect(() => {
    const decoded: any = jwt_decode(getAuthenticationHeaders()?.['access-token']);
    setState({
      decoded
    })
  },[])

  const { classes, userDetails, history } = props;
  const [state, setState] = useGenericState<any>({
    isLogoutPopupVisible: false,
    decoded: null
  });

  const { isLogoutPopupVisible, decoded } = state;

  const handleClickOutside = () => {
    setState({ isLogoutPopupVisible: false });
  };

  return (
    <div className={classes.navbar} id="navbar">
      <div className={classes.leftFlex}>
        <span>{<img src={Logo} alt="" />}</span>
      </div>
      <div className={classes.rightTools}>
        { decoded?.role === RolesType.ADMIN && (
          <div
            style={{ marginRight: '12px', cursor: 'pointer' }}
            onClick={() => history.push(getAdminRoute())}
          >
            <AdminManage />
          </div>
        )}
        <span
          style={{
            fontSize: '16px',
            color: '#111111',
            fontWeight: 600,
            cursor: 'pointer'
          }}
          onClick={() => setState({ isLogoutPopupVisible: true })}
        >
          {userDetails?.name}
          <img
            src={userDetails?.profilePicUrl}
            alt=""
            className={classes.profilePic}
          />
        </span>
        {isLogoutPopupVisible && (
          <LogoutPopup
            handleClickOutside={handleClickOutside}
            history={history}
            userDetails={userDetails}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ loginReducer }: any, ownProps: any) => ({
  userDetails: loginReducer?.userDetails
});

const NavbarStyled = withStyles(styles)(Navbar);
export default connect(mapStateToProps, null)(NavbarStyled);
