import * as React from 'react';
import withStyles from 'react-jss';
import { Row, Col, Form, Button, message } from 'antd';
import signIn from '../cheerIcons/signIn.svg';
import googleLogo from '../cheerIcons/googleLogo.png';
import { FormComponentProps } from 'antd/lib/form'; // eslint-disable-line
import { buttonStyle } from '../library/CommonStyles';
import { oAuthStart } from '../api/apis';
import { useGenericState } from '../library/UseGenericState';
import { withRouter, RouterProps } from 'react-router'; // eslint-disable-line
import { slackSigninUrl } from '../globals';

interface IProps extends FormComponentProps, RouterProps {
  classes: any;
}

const styles = {
  signInPage: {
    paddingTop: '12%',
    minWidth: 1200
  },
  input: {
    marginTop: '8px',
    width: '100%',
    fontSize: '16px',
    border: 'unset',
    borderBottom: '1px solid #CCCCCC',
    color: '#333333',
    padding: 0,
    borderRadius: 0,
    '&:placeholder-shown': {
      color: '#CCCCCC',
      marginBottom: '8px'
    },
    '&:hover': {
      borderBottom: '1px solid #CCCCCC'
    },
    '&:focus': {
      borderBottom: '1px solid #CCCCCC',
      boxShadow: 'unset !important'
    }
  },
  title: {
    fontSize: '12px',
    color: '#333333',
    fontWeight: 600
  },
  button: {
    ...buttonStyle('#FFFFFF', '#111111', '1px solid #111111'),
    width: '100%',
    textAlign: 'center',
    height: '60px',
    fontSize: '18px'
  },
  slackButtonStyles: {
    height: '52px',
    width: '170px',
    fontSize: '14px',
    boxShadow: 'none',
    border: '1px solid',
    borderRadius: '4px'
  }
};

interface StateProps {
  isLoading?: boolean;
}

const Login = (props: IProps): React.ReactElement => {
  const { classes } = props;
  const [state, setState] = useGenericState<StateProps>({});

  const { isLoading } = state;

  const signInToSlack = () => {
    window.location.href = slackSigninUrl;
  }

  return (
    <div className={classes.signInPage}>
      <Row style={{ display: 'flex', alignItems: 'center' }}>
        <Col span={14} style={{ textAlign: 'center' }}>
          <img src={signIn} alt="" />
        </Col>
        <Col span={10} style={{ paddingRight: '11%' }}>
          <div
            style={{ color: '#111111', fontWeight: 'bold', fontSize: '24px' }}
          >
            Sign In
          </div>
          <div style={{ textAlign: 'left', marginBottom: 16, marginTop: 16 }}>
            <Button
              className={classes.slackButtonStyles}
              onClick={() => {
                signInToSlack();
              }}
              loading={isLoading}
            >
              <img
                style={{ marginRight: '10px' , height: '24px', width: '24px'}}
                src={'https://shipsy-public-assets.s3-us-west-2.amazonaws.com/exim/slack_icon.png'}
                alt="Slack Sign-In"
              />
              Slack Account
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(withStyles(styles)(Form.create()(Login)));
