import apiProvider from './provider';
import { getAuthenticationHeaders, getErrorMessage } from './utils';
import store from '../store/appStore';
import { FORCE_LOGOUT } from '../components/action.constants';

export const GET = async (
  url: string,
  params?: any,
  headers: any = getAuthenticationHeaders()
): Promise<any> => {
  try {
    const response = await apiProvider.get(url, {
      params,
      headers
    });
    return {
      isSuccess: true,
      data: response?.data?.data,
      status: response.status
    };
  } catch (err) {
    if (err?.response?.status === 401) {
      store.dispatch({
        type: FORCE_LOGOUT,
        data: true
      });
      return {
        isSuccess: false
      };
    }
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false
    };
  }
};

export const POST = async (
  url: string,
  body?: any,
  authorize: boolean = true,
  headers = getAuthenticationHeaders()
): Promise<any> => {
  try {
    const meta = {
      headers
    };
    const response = await apiProvider.post(url, body, authorize ? meta : {});
    const dataToReturn = {
      isSuccess: true,
      data: response?.data?.data
    };
    return dataToReturn;
  } catch (err) {
    if (authorize && err?.response?.status === 401) {
      store.dispatch({
        type: FORCE_LOGOUT,
        data: true
      });
      return {
        isSuccess: false
      };
    }
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false
    };
  }
};

export const PUT = async (
  url: string,
  file: any,
  headers: any = getAuthenticationHeaders()
): Promise<any> => {
  try {
    await apiProvider.put(url, file, { headers });
    return {
      isSuccess: true
    };
  } catch (err) {
    if (err?.response?.status === 401) {
      store.dispatch({
        type: FORCE_LOGOUT,
        data: true
      });
      return {
        isSuccess: false
      };
    }
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false
    };
  }
};

export const DELETE = async (
  url: string,
  headers: any = getAuthenticationHeaders()
): Promise<any> => {
  try {
    await apiProvider.delete(url, { headers });
    return {
      isSuccess: true
    };
  } catch (err) {
    if (err?.response?.status === 401) {
      store.dispatch({
        type: FORCE_LOGOUT,
        data: true
      });
      return {
        isSuccess: false
      };
    }
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false
    };
  }
};

export const PATCH = async (
  url: string,
  data: any,
  headers: any = getAuthenticationHeaders()
): Promise<any> => {
  try {
    await apiProvider.patch(url, data, { headers });
    return {
      isSuccess: true
    };
  } catch (err) {
    if (err?.response?.status === 401) {
      store.dispatch({
        type: FORCE_LOGOUT,
        data: true
      });
      return {
        isSuccess: false
      };
    }
    const errorMessage = getErrorMessage(err);
    return {
      errorMessage,
      isSuccess: false
    };
  }
};
