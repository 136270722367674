import * as React from 'react';
declare let window: any;
const withAuth = (Component: any) => {
  return class WithAuth extends React.PureComponent<any, any> {
    state = {
      isAuthenticated: false,
      isTeamSelected: false,
      isLoading: true
    };

    async componentDidMount() {
      const storage = window.localStorage;
      const isAuthenticated =
        storage?.getItem('id') && storage?.getItem('authToken');
        const isTeamSelected =
        storage?.getItem('id') && storage?.getItem('teamId');  
      this.setState({
        isLoading: false,
        isAuthenticated,
        isTeamSelected
      });
    }

    render() {
      const { isLoading, isAuthenticated, isTeamSelected } = this.state;
      if (isLoading) {
        return null;
      }
      return <Component {...this.props} authenticated={isAuthenticated} team={isTeamSelected}/>;
    }
  };
};

export default withAuth;
