import {message} from 'antd';
import * as React from 'react';
import withStyles from 'react-jss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchCatalogs } from '../api/apis';
import { Catalog } from '../library/Types';
import { styles } from '../styles/catalogcards.styles';
import Loader from '../cheerIcons/Loader.gif';
import { setCatalogDetails } from './Login.actions';
import CatalogCard from './CatalogCard';
import { useGenericState } from '../library/UseGenericState';
import CatalogModal from './CatalogModal';
import { ActionTypes } from './utils';

interface State {
  isFetchingCatalogs: boolean;
  isCatalogModalVisible: boolean;
  action: string;
  activeCatalog: any;
}

const CatalogCardsWrapper = (props: any) => {
  const { classes, setCatalogDetails, catalogs, isAdmin, redeemableBeers } = props;
  const [state, setState] = useGenericState<State>({
    isFetchingCatalogs: false,
    isCatalogModalVisible: false,
    action: '',
    activeCatalog: null
  });
  const { isFetchingCatalogs, isCatalogModalVisible, action, activeCatalog } = state;
  React.useEffect(() => {
    fetchCatalogsData();
  },[]);

  const fetchCatalogsData = async () => {
    setState({isFetchingCatalogs: true});
    const response = await fetchCatalogs();
    if(response.isSuccess){
      setCatalogDetails(response.data);
    } else {
      message.error(response.errorMessage);
    }
    setState({isFetchingCatalogs: false});
  }

  return (
    <div className={classes.catalogWrapper} style={{ padding: isAdmin ? '0px' : '16px 0px'}}>
      { !isFetchingCatalogs ? 
        <>
          { isAdmin && 
            <div className={classes.addItemCatalogCard} onClick={() => setState({isCatalogModalVisible: true, action: ActionTypes.CREATE})}>
              <div className={classes.plusHorizontal}></div>
              <div className={classes.plusVertical}></div>
              <div style={{fontSize: '18px', color: '#121212', marginTop: '24px'}}>Add Item</div>
            </div>
          }
          { catalogs?.filter((catalog: Catalog) => isAdmin || catalog.isActive ).map((catalog: Catalog) => {
              return <CatalogCard classes={classes} catalog={catalog} redeemableBeers={redeemableBeers} isAdmin={isAdmin} setAction={(action:string) => setState({action})} handleOpenEditCatalogModal={() => setState({isCatalogModalVisible: true, activeCatalog: catalog})} fetchCatalogsData={fetchCatalogsData}/>
            })
          }
        </> : 
        <div style={{ position: 'absolute', top: '48%', left: '48%' }}>
            <img src={Loader} alt="" style={{ height: '100px' }}/>
        </div>
      }
      { isCatalogModalVisible && 
        <CatalogModal 
          classes={classes}
          isVisible={isCatalogModalVisible} 
          handleClose={() => setState({isCatalogModalVisible: false})}
          fetchCatalogsData={fetchCatalogsData}
          action={action}
          catalog={activeCatalog}
        />
      }
    </div>
  );
}

const mapStateToProps = ({ loginReducer }: any, ownProps: any) => ({
  catalogs: loginReducer?.catalogs
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      setCatalogDetails
    },
    dispatch
  );

export default React.memo(connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(CatalogCardsWrapper)));