import { Button, Carousel, Tooltip } from 'antd';
import * as React from 'react';
import { Catalog } from '../library/Types';
import withStyles from 'react-jss';
import Loader from '../cheerIcons/Loader.gif';
import Beers from '../cheerIcons/Beers';
import Lock from '../cheerIcons/Lock';
import { useGenericState } from '../library/UseGenericState';
import { styles } from '../styles/catalogcards.styles';
import OrderDetailsModal from './OrderDetailsModal';
import OrderSuccessModal from './OrderSuccessModal';
import CheerSuccessModal from './CheerSuccessModal';
import Delete from '../cheerIcons/Delete';
import Edit from '../cheerIcons/Edit';
import { ActionTypes } from './utils';
import Deactivate from '../cheerIcons/Deactivate';
import Activate from '../cheerIcons/Activate';
import DeleteModal from './DeleteModal';
import ActivateCatalogModal from './CatalogStatusConfirmModal';
import beers from '../cheerIcons/beers.svg';


interface CatalogProps {
  classes: {
    [X in keyof (typeof styles)]: string;
  };
  catalog: Catalog;
  redeemableBeers: number;
  isAdmin: boolean;
  setAction: (action:string) => void;
  handleOpenEditCatalogModal: () => void;
  fetchCatalogsData: () => void;
};

interface State {
  isFetchingImages: boolean;
  isOrderDetailsModalVisible: boolean;
  isOrderSuccessModalVisible: boolean;
  isDeleteModalVisible: boolean;
  action: string
}

const CatalogCard = (props: CatalogProps) => {
  const { classes, catalog, redeemableBeers, isAdmin, setAction, handleOpenEditCatalogModal, fetchCatalogsData } = props;
  const [state, setState] = useGenericState<State>({
    isFetchingImages: true,
    isOrderDetailsModalVisible: false,
    isOrderSuccessModalVisible: false,
    isDeleteModalVisible: false,
    action: ''
  });
  const { isFetchingImages, isOrderDetailsModalVisible, isOrderSuccessModalVisible, isDeleteModalVisible, action } = state;
  const canBuy = (redeemableBeers >= catalog.price);

  const handleUpdate = () => {
    if(catalog.isActive){
      setState({isDeleteModalVisible: true, action: ActionTypes.DEACTIVATE});
    } else {
      setState({isDeleteModalVisible: true, action: ActionTypes.ACTIVATE});
    }
  }

  const renderImageCarousel = () => {
    return (
      <div style={{ height: '395px' }}>
        <Carousel>
          {catalog?.imageUrls?.map((image) => {
            return (
              <>
                {isFetchingImages && <img src={Loader} alt='' style={{ height: '100px',margin: '38% 0 0 38%'}}/>}
                <img src={image} style={{ display: isFetchingImages ? 'none' : 'block'}} className={classes.images}  onLoad={() => setState({ isFetchingImages: false})}/>
              </>
            );  
          })}
        </Carousel>
      </div>
    );
  }

  const renderPurchaseWrapper = () => {
    return (
      <div className={classes.purchaseWrapper}>
        <div>
          <span className={classes.price}><img src={beers} alt="" style={{ position: 'relative', top: '-3px' }}/> {catalog.price} Beers</span>
        </div>
        {!isAdmin ? (
          <div className={classes.rightWrapper}>
            { !canBuy && <Lock /> }
            <Button disabled = {!canBuy}
              className={ canBuy ? classes.button : classes.buttonDisabled}
              onClick={() => setState({isOrderDetailsModalVisible: true})}>
              Buy Now
            </Button>
          </div>) : 
          (
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Tooltip title='Edit'>
                <div style={{ marginRight: '16px', display: 'flex', cursor: 'pointer'}} onClick={() => {
                  setAction(ActionTypes.UPDATE);
                  handleOpenEditCatalogModal();
                }}><Edit /></div>
              </Tooltip>
              <Tooltip title={catalog.isActive ? 'Unpublish' : 'Publish'}><div style={{ marginRight: '16px', display: 'flex', cursor: 'pointer'}} onClick={handleUpdate}>{catalog.isActive ? <Deactivate/> : <Activate/>}</div></Tooltip>
              <Tooltip title='Delete'>
                <div style={{display: 'flex', cursor: 'pointer'}} onClick={() => setState({action: ActionTypes.DELETE, isDeleteModalVisible: true})}><Delete/></div>
              </Tooltip>  
            </div>
          )
        }
      </div>
    );
  }


  return (
    <div className={classes.catalogCard} style={{opacity: catalog.isActive ? '100%' : '30%'}}>
      {renderImageCarousel()}
      <div className={classes.title}>{catalog.title}</div>
      <Tooltip title={catalog.description}>
        <div className={classes.description}>{catalog.description}</div>
      </Tooltip>
      {renderPurchaseWrapper()}
      { isOrderDetailsModalVisible && 
        <OrderDetailsModal 
          isVisible={isOrderDetailsModalVisible} 
          handleClose={() => setState({isOrderDetailsModalVisible: false})}
          catalog={catalog}
          isFetchingImages={isFetchingImages}
          handleOpenOrderSuccessModal={() => setState({isOrderSuccessModalVisible: true, isOrderDetailsModalVisible: false})}
        />
      }
      { isOrderSuccessModalVisible && 
        <OrderSuccessModal 
          isVisible={isOrderSuccessModalVisible} 
          handleClose={() => setState({isOrderSuccessModalVisible: false})}
        />
      }
      { isDeleteModalVisible && 
        <ActivateCatalogModal 
          isVisible={isDeleteModalVisible} 
          handleClose={() => setState({isDeleteModalVisible: false})}
          catalog={catalog}
          action={action}
          fetchCatalogsData={fetchCatalogsData}
        />
      }
    </div>
  );
}


export default React.memo(withStyles(styles)(CatalogCard));

