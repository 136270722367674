export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const IS_FETCHING_FEED = 'IS_FETCHING_FEED';
export const SET_FEED_DATA = 'SET_FEED_DATA';
export const SET_FEED_DATA_ERROR = 'SET_FEED_DATA_ERROR';
export const SET_FILTERS = 'SET_FILTERS';
export const IS_FETCHING_ACTIVITY = 'IS_FETCHING_ACTIVITY';
export const SET_ACTIVITY_LIST = 'SET_ACTIVITY_LIST';
export const SET_ACTIVITY_LIST_ERROR = 'SET_ACTIVITY_LIST_ERROR';
export const FORCE_LOGOUT = 'FORCE_LOGOUT';
export const SET_USER_BEERS = 'SET_USER_BEERS';
export const SET_CATALOG_DETAILS = 'SET_CATALOG_DETAILS';
export const SET_MASTER_DATA = 'SET_MASTER_DATA';
