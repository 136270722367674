import * as React from 'react';
import withStyles from 'react-jss';
import { Address, Catalog, RouterProps } from '../library/Types'; // eslint-disable-line
import { Modal, Button, message, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Delete from '../cheerIcons/Delete';
import Cross from '../cheerIcons/Cross';
import { styles } from '../styles/orderdetailsmodal.styles';
import { useGenericState } from '../library/UseGenericState';
import Loader from '../cheerIcons/Loader.gif';
import { createOrder, fetchUserAddress, fetchUserBeers } from '../api/apis';
import Edit from '../cheerIcons/Edit';
import Beers from '../cheerIcons/Beers';
import AddressModal from './AddressModal';
import DeleteModal from './DeleteModal';
import { ActionTypes } from './utils';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setUserBeers } from './Login.actions';
import * as crypto from 'crypto';
interface IProps extends RouterProps {
  classes: {
    [X in keyof (typeof styles)]: string;
  };
  handleClose: () => void;
  isVisible: boolean;
  catalog: Catalog;
  handleOpenOrderSuccessModal: () => void;
  setUserBeers: (beers: string) => void;
  userDetails: any;
}

interface State {
  userAddress: Address[];
  isFetchingUserAddress: boolean;
  isPlacingNewOrder: boolean;
  activeAddress: number;
  isAddressModalVisible: boolean;
  isDeleteModalVisible: boolean;
  action: string;
  address: any;
}

const OrderDetailsModal = (props: IProps): React.ReactElement => {
  const { classes, handleClose, isVisible, catalog, handleOpenOrderSuccessModal, setUserBeers, userDetails } = props;

  const [state, setState] = useGenericState<State>({
    userAddress : [],
    isFetchingUserAddress: false,
    isPlacingNewOrder: false,
    activeAddress: -1,
    isAddressModalVisible: false,
    isDeleteModalVisible: false,
    action: '',
    address: {}
  });

  const { userAddress, isFetchingUserAddress, activeAddress, isAddressModalVisible, action, address, isDeleteModalVisible, isPlacingNewOrder } = state;

  React.useEffect(() => {
    fetchUserAddressData();
  },[]);

  const remarkEl = React.useRef(null);

  const fetchUserAddressData = async () => {
    setState({ isFetchingUserAddress: true });
    let userAddress = [], activeAddress = -1;
    const response = await fetchUserAddress();
    if(response.isSuccess){
      userAddress = response.data;
      activeAddress = response.data.length ? response.data[0].id : -1
    } else {
      message.error(response.errorMessage);
    }
    setState({ userAddress, activeAddress, isFetchingUserAddress: false });
  };

  const fetchUserBeersData = async () => {
    const response = await fetchUserBeers();
    if (response.isSuccess) {
      const { redeemableBeers } = response.data;
      const decipher = crypto.createDecipher('aes256', userDetails.id);
      let decrypted = '0';
      try{
        decrypted = decipher.update(redeemableBeers, 'hex', 'utf8') + decipher.final('utf8');
      }
      catch(err) {}
      setUserBeers(decrypted);
    } else {
      message.error(response.errorMessage);
    }
  }

  const handlePlaceOrder = async (catalogId: number, addressId: number) => {
    const note = {
      // @ts-ignore: Object is possibly 'null'.
      user: remarkEl.current.state.value
    }
    setState({isPlacingNewOrder: true})
    const response = await createOrder({ note }, catalogId, addressId);
    if(response.isSuccess){
      handleOpenOrderSuccessModal();
      fetchUserBeersData();
    } else {
      message.error(response.errorMessage);
    }
    setState({isPlacingNewOrder: false});
  }

  const renderPrettyAddress = (address: Address) => {
    return (
      <p>
        {address.line1} <br/>
        {address.line2} {address.line2 && <br/>}
        {address.city} {address.pincode}, {address.state} <br />
        {address.phone}
      </p>               
    );
  }

  const renderAddressWrapper = () => {
    return ( userAddress.length ?
      <div className={classes.addressWrapper}>
        {userAddress.map((address: any) => {
          return (
            <div className={classes.address} style={ address.id === activeAddress ? 
              {
                border: '1px solid #EEBB5D',
                backgroundColor: '#FFE3AF'
              } : {}}
              onClick={() => setState({activeAddress: address.id})}
            >
              { renderPrettyAddress(address) }
              <Tooltip title='Delete'>
                <div className={classes.deleteIcon} onClick={() => setState({ isDeleteModalVisible: true, address })}>
                  <Delete/>
                </div>
              </Tooltip>
              <Tooltip title='Edit'>
                <div className={classes.editIcon} onClick={() => setState({ isAddressModalVisible: true, action: ActionTypes.UPDATE, address})}>
                  <Edit/>
                </div>
              </Tooltip>
            </div>
          );
        })}
      </div> : 
      <div className={classes.emptyAddressState}>
        <div style={{fontSize: '16px'}}>No Address Found!</div>
        <div style={{fontSize: '12px'}}>You haven't added any address yet! Add new address to purchase your item.</div>
      </div>
    );
  }

  const renderPurchaseButton = () => {
    return (
      <Tooltip title={activeAddress<0 ? 'Select Delivery Address' : ''}>
        <Button className={classes.button} loading={isPlacingNewOrder} disabled={activeAddress<0} style={{display: 'flex'}} onClick={() => handlePlaceOrder(Number(catalog.id), Number(activeAddress))}>
          <span className={(activeAddress>0)? classes.buyNow: classes.buyNowDisabled}>Buy Now</span>  
        </Button>
      </Tooltip>
    );
  }

  const renderOrderDetails = () => {
    return (
      <div className={classes.orderDetails}>
        <div className={classes.orderDetailsTitle}>Order Details</div>
        <div className={classes.orderDetailsInfo}>
        <img src={catalog.imageUrls[0]} className={classes.catalogImage} />
          <div className={classes.catalogInfo}>
            <div className={classes.catalogTitle}>{catalog.title}</div>
            <div className={classes.catalogPrice}><Beers/> {catalog.price} Beers</div>
          </div>
        </div>
      </div>
    );
  }

  const renderRemarkBox = () => {
    return (
      <div className={classes.remark}>
        <div className={classes.remarkTitle}>Remark</div>
        <TextArea
          placeholder="Write a remark"
          autoSize={{ minRows: 3, maxRows: 3 }}
          ref={remarkEl}
        />
      </div>
    );
  }

  return (
    <Modal
      className={classes.modal}
      visible={isVisible}
      onCancel={handleClose}
      closable={false}
      footer={null}
    >
      <div className={classes.parent}>
        <div className={classes.header}>
          <div className={classes.leftHeader}>
            <div className={classes.cross} onClick={handleClose}><Cross /></div>
            <div className={classes.title}>Your Order</div>
          </div>
          {renderPurchaseButton()}
        </div>
        <div className={classes.body}>
          <div className={classes.orderDetailsWrapper}>
            {renderOrderDetails()}
            {renderRemarkBox()}
          </div>
          <div>
            <div className={classes.selectAddressHeader}>
              <div>Select Delivery Address*</div>
              <div className={classes.addNewAddress} onClick={() => setState({isAddressModalVisible: true, action: ActionTypes.CREATE})}>+ New Address</div>
            </div>
            { isFetchingUserAddress ? 
            <img src={Loader} alt='' style={{ height: '100px',margin: '10% 0 12.5% 0'}}/> : 
            renderAddressWrapper()
            }
          </div>
        </div>
      </div>
      { isAddressModalVisible && 
        <AddressModal 
          isVisible={isAddressModalVisible} 
          handleClose={() => setState({isAddressModalVisible: false})}
          fetchUserAddressData={fetchUserAddressData}
          action={action}
          address={address}
        />
      }
      { isDeleteModalVisible && 
        <DeleteModal 
          isVisible={isDeleteModalVisible} 
          handleClose={() => setState({isDeleteModalVisible: false })}
          handleActiveAddress={() => setState({ address: undefined, activeAddress: -1 })}
          fetchUserAddressData={fetchUserAddressData}
          address={address}
        />
      }
    </Modal>
  );
};

const mapStateToProps = ({ loginReducer }: any, ownProps: any) => ({
  userDetails: loginReducer?.userDetails,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      setUserBeers,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrderDetailsModal));