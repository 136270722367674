export const styles = {
  modal: {
    minWidth: '680px',
    minHeight: '460px',
    '& .ant-modal-content': {
      borderRadius: '16px'
    },
    '& .ant-modal-body': {
      padding: '0px !important',
      textAlign: 'center',
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      border: '1px solid #F2F2F2',
      display: 'flex',
      alignItems: 'center'
    },
    '& .ant-input': {
      height: '36px !important'
    },
    '& .ant-select-selection--single': {
      height: '36px !important'
    },
    '& .ant-select': {
      width: '-webkit-fill-available'
    },
    '& .has-error .ant-form-explain': {
      display: 'flex'
    },
    '& .ant-form-vertical .ant-form-item':{
      width: '-webkit-fill-available'
    }
  },
  parent: {
    display: 'flex',
    flexDirection: 'column',
    width: '-webkit-fill-available'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '22px 24px',
    alignItems: 'center',
    borderBottom: '1px solid #CCCCCC'
  },
  leftHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  cross: {
    marginRight: '16px',
    fontSize: '16px',
    display: 'flex',
    cursor: 'pointer'
  },
  catalogModalTitle: {
    color: '#333333',
    fontSize: '16px',
    fontWeight: 500
  },
  publishButton: {
    borderRadius: '6px',
    backgroundColor: '#111111',
    color: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:focus': {
      color: '#FFFFFF',
      backgroundColor: 'rgba(1,1,1,0.8)',
    },
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: 'rgba(1,1,1,0.8)',
    }
  },
  publish: {
    color: '#FFFFFF',
    fontSize: '12px',
    letterSpacing: '0.2px',
    lineHeight: '20px',
    textAlign: 'center'
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px'
  },
  itemInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  itemName:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 5,
    marginRight: '12px'
  },
  priceWrapper:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  itemTitle:{
    marginBottom: '6px'
  },
  itemDescription: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  imagesWrapper:{
    display: 'flex'
  },
  image: {
    width: '100px',
    height: '100px',
    backgroundColor: 'blue',
    marginRight: '16px'
  },
  itemImagesWrapper:{
    flexDirection: 'column',
    marginBottom: '24px',
    alignItems: 'flex-start',
  },
  itemImageText:{
    marginBottom: '8px'
  },
  spin: {
    '& .ant-spin-dot-item': {
      backgroundColor: '#FFD17B'
    },
    marginLeft: '6px'
  }
};