import * as React from 'react';
import withAuth from './hoc/withAuth';
import RouterOutlet from './routing/RouterOutlet';
import { getLoginRoute , getLogoutRoute, getSelectTeamRoute} from './routing/utils';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

class App extends React.PureComponent<any, any> {
  render() {
    const { authenticated , team, forceLogout} = this.props;
    if (forceLogout) {
      return <Redirect to={{ pathname: getLogoutRoute() }} />;
    }
    if (!authenticated) {
      return <Redirect to={{ pathname: getLoginRoute() }} />;
    }
    if(!team){
      return <Redirect to={{ pathname: getSelectTeamRoute() }} />;
    }
    return <RouterOutlet />;
  }
}

const mapStateToProps = ({ loginReducer }: any, ownProps: any) => ({
  userDetails: loginReducer?.userDetails,
  forceLogout: loginReducer?.forceLogout
});

export default connect(mapStateToProps)(withAuth(App));
