export const styles = {
  catalogWrapper:{
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '1.5vw'
  },
  catalogCard: {
    boxSizing: 'border-box',
    height: '576px',
    maxWidth: '31vw',
    border: '1px solid #FCFCFC',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    padding: 24,
    '& .ant-carousel .slick-list .slick-slide.slick-active':{
      height: '395px'
    },
    '& .ant-carousel .slick-dots li button':{
      width: '12px',
      height: '12px',
      border: '1px solid #979797',
      borderRadius: '50%',
      opacity: 1
    },
    '& .ant-carousel .slick-dots-bottom':{
      bottom: '-18px'
    },
    '& .ant-carousel .slick-dots li.slick-active button':{
      background: '#FFBE47',
      border: '1px solid #FFBE47 !important'
    }
  },
  button: {
    fontSize: '12px',
    letterSpacing: 1,
    lineHeight: '20px',
    textAlign: 'center',
    padding: '6px 12px',
    borderRadius: '6px',
    backgroundColor: '#111111',
    color: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '&:focus': {
      color: '#111111',
      backgroundColor: '#FFD17B',
      border: '1px solid #FFD17B'
    },
    '&:hover': {
      color: '#111111',
      backgroundColor: '#FFD17B',
      border: '1px solid #FFD17B'
    },
  },
  buttonDisabled: {
    fontSize: '12px',
    letterSpacing: 1,
    lineHeight: '20px',
    textAlign: 'center',
    color: '#D3D3D3',
    backgroundColor: '#CCCCCC',
    border: '1px solid #CCCCCC',
    '&:focus': {
      color: '#D3D3D3',
      backgroundColor: '#CCCCCC',
      border: '1px solid #CCCCCC'
    },
    '&:hover': {
      color: '#D3D3D3',
      backgroundColor: '#CCCCCC',
      border: '1px solid #CCCCCC'
    },
    padding: '6px 12px',
    borderRadius: '6px',
    cursor: 'not-allowed',
    marginLeft: '8px'
  },
  title:{
    fontSize: '18px',
    fontWeight: 'bold',
    marginTop: '36px',
    color: '#121212',
  },
  description: {
    fontSize: '12px',
    color: '#111111',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  purchaseWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '20px'
  },
  rightWrapper:{
    display: 'flex',
    alignItems: 'center'
  },
  price: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#121212',
  },
  dot:{
    width: '12px',
    height: '12px',
    border: '1px solid #979797'
  },
  images: {
    objectFit: 'cover',
    width: '100%',
    height: '395px'
  },
  addItemCatalogCard:{
    boxSizing: 'border-box',
    height: '576px',
    maxWidth: '31vw',
    border: '1px solid #FCFCFC',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  plusHorizontal: {
    backgroundColor: '#999999',
    height: '8px',
    width: '80px',
    borderRadius: '5px',
    transform: 'translateY(-35px)',
    cursor: 'pointer'
  },
  plusVertical: {
    backgroundColor: '#999999',
    height: '8px',
    width: '80px',
    borderRadius: '5px',
    transform: 'rotateZ(90deg) translateX(-45px)',
    cursor: 'pointer'
  }
}
