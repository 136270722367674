import * as React from 'react';

export default () => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" >
        <title>Back</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Back" transform="translate(12.000000, 12.000000) rotate(-180.000000) translate(-12.000000, -12.000000) ">
                <g id="arrowright" transform="translate(2.953125, 4.312500)" fill="#333333" fill-rule="nonzero">
                    <path d="M1.059375,6.65625 L14.5054688,6.65625 C14.5078125,6.65625 14.5101563,6.6515625 14.5078125,6.6515625 L9.4359375,1.7765625 C9.02578125,1.3828125 9.01171875,0.72890625 9.4078125,0.31875 C9.8015625,-0.09140625 10.4554688,-0.10546875 10.865625,0.290625 L17.7726563,6.928125 C17.9742188,7.12265625 18.0890625,7.3921875 18.0890625,7.67109375 C18.0890625,7.95234375 17.9742188,8.21953125 17.7726563,8.4140625 L10.828125,15.0867188 C10.6289063,15.2789063 10.3710938,15.375 10.1132813,15.375 C9.84140625,15.375 9.571875,15.2695313 9.3703125,15.0585938 C8.9765625,14.6484375 8.98828125,13.9945313 9.3984375,13.6007813 L14.4703125,8.72578125 C14.4726563,8.7234375 14.4703125,8.72109375 14.4679688,8.72109375 L1.0359375,8.72109375 C0.4875,8.72109375 0.03984375,8.2921875 0.00703125,7.753125 C-0.028125,7.153125 0.46171875,6.65625 1.059375,6.65625 Z" id="Shape"></path>
                </g>
                <rect id="Rectangle-37-Copy" x="0" y="0" width="24" height="24"></rect>
            </g>
        </g>
    </svg>
  );
}