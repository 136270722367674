import * as React from 'react';

export default () => {
  return (
    <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>check-circle-o copy</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="check-circle-o-copy" fill="#333333" fill-rule="nonzero">
                <path d="M9.86152344,4.178125 L5.38398437,8.66933594 L4.21640625,6.62539063 C4.08105469,6.38886719 3.78164062,6.30683594 3.54511719,6.4421875 C3.30859375,6.57753906 3.2265625,6.87695313 3.36191406,7.11347656 L4.84941406,9.71796875 C4.93964844,9.8765625 5.10644531,9.96679688 5.27734375,9.96679688 C5.36074219,9.96679688 5.44414062,9.94628906 5.52070312,9.90253906 C5.56445312,9.8765625 5.60410156,9.84648438 5.63691406,9.8109375 C5.63828125,9.80957031 5.64101562,9.80683594 5.64238281,9.80546875 L10.5574219,4.87539063 C10.7488281,4.68261719 10.7488281,4.37089844 10.5560547,4.17949219 C10.3646484,3.98535156 10.0542969,3.98535156 9.86152344,4.178125 Z M7,0 C3.13359375,0 0,3.13359375 0,7 C0,10.8664062 3.13359375,14 7,14 C10.8664062,14 14,10.8664062 14,7 C14,3.13359375 10.8664062,0 7,0 Z M11.2533203,11.2533203 C10.7009766,11.8056641 10.0570313,12.2404297 9.340625,12.5425781 C8.59960938,12.8570313 7.81210938,13.015625 7,13.015625 C6.18789063,13.015625 5.40039062,12.8570313 4.659375,12.5439453 C3.94296875,12.2404297 3.30039062,11.8070312 2.74667969,11.2546875 C2.19433594,10.7023438 1.75957031,10.0583984 1.45742188,9.34199219 C1.14296875,8.59960938 0.984375,7.81210938 0.984375,7 C0.984375,6.18789063 1.14296875,5.40039062 1.45605469,4.659375 C1.75957031,3.94296875 2.19296875,3.30039062 2.7453125,2.74667969 C3.29765625,2.19433594 3.94160156,1.75957031 4.65800781,1.45742188 C5.40039062,1.14296875 6.18789063,0.984375 7,0.984375 C7.81210938,0.984375 8.59960938,1.14296875 9.340625,1.45605469 C10.0570313,1.75957031 10.6996094,2.19296875 11.2533203,2.7453125 C11.8056641,3.29765625 12.2404297,3.94160156 12.5425781,4.65800781 C12.8570313,5.40039062 13.015625,6.18789063 13.015625,7 C13.015625,7.81210938 12.8570313,8.59960938 12.5439453,9.340625 C12.2404297,10.0570313 11.8070312,10.7009766 11.2533203,11.2533203 Z" id="Shape"></path>
            </g>
        </g>
    </svg>
  );
}