import { InputNumber, Form, Input, Select } from 'antd';
import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { Address } from '../library/Types';
import { ActionTypes } from './utils';
import { styles } from '../styles/createaddressmodal.styles';

interface Props extends FormComponentProps<any>{
  classes: {
    [X in keyof (typeof styles)]: string;
  };
  handleSaveAddress: (address: Address) => void;
  handleUpdateAddress: (address: Address) => void;
  action: string,
  address: Address,
  states: string[];
}

const AddressForm = (props: Props): React.ReactElement => {
  const { classes, form, handleSaveAddress, handleUpdateAddress, action, address, states } = props;
  const { getFieldDecorator, getFieldValue } = form;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        const address = {
          line1: getFieldValue('address line1'),
          line2: getFieldValue('address line2'),
          city: getFieldValue('city'),
          state: getFieldValue('state'),
          pincode: getFieldValue('pincode').toString(),
          phone: getFieldValue('phone').toString(),
        }
        if(action === ActionTypes.CREATE) handleSaveAddress(address);
        else if (action === ActionTypes.UPDATE) handleUpdateAddress(address);
      } 
    });
  }

  return (
    <Form id='addressForm' layout='vertical' onSubmit={handleSubmit}>
      <Form.Item label='Address Line 1'>
      {getFieldDecorator('address line1', {
          rules: [{ required: true, message: 'Please input your address line 1!' }],
          initialValue: action === ActionTypes.UPDATE ? address.line1 : ''
        })(
          <Input placeholder="Address Line 1"/>
        )}
      </Form.Item>
      <Form.Item label='Address Line 2' required={false}>
        {getFieldDecorator('address line2',{
          initialValue: action === ActionTypes.UPDATE ? address.line2 : ''
        })(
          <Input placeholder="Address Line 2" />
        )
        }
      </Form.Item>
      <div className={classes.addressLine3Wrapper}>
        <div className={classes.pincode}>
          <Form.Item label='Pincode'>
          {getFieldDecorator('pincode', {
              rules: [
                { required: true, message: 'Please input your pincode!' },
              ],
              initialValue: action === ActionTypes.UPDATE ? address.pincode : ''
            })(
              <InputNumber placeholder="Pincode" type="number"/>
            )}
          </Form.Item>
        </div>
        <div className={classes.city}>
          <Form.Item label='City'>
          {getFieldDecorator('city', {
              rules: [{ required: true, message: 'Please input your city!' }],
              initialValue: action === ActionTypes.UPDATE ? address.city : ''
            })(
              <Input placeholder="City" />
            )}
          </Form.Item>
        </div>
        <div className={classes.state}>
          <Form.Item label='State'>
          {getFieldDecorator('state', {
              rules: [{ required: true, message: 'Please select your state!' }],
              ...(action === ActionTypes.UPDATE && {initialValue: address.state})
            })(
              <Select placeholder="State" showSearch>
              {states.map((item: any) => {
                return <Select.Option value={item}>{item}</Select.Option>;
              })}
            </Select>
            )}
          </Form.Item>
        </div>
      </div>
      <div className={classes.contact}>
        <Form.Item label='Phone' style={{width: '250px'}}>
        {getFieldDecorator('phone', {
            rules: [
              { required: true, message: 'Please input your contact number!' },
            ],
            initialValue: action === ActionTypes.UPDATE ? address.phone : ''
          })(
            <InputNumber placeholder="Contact No" type="number"/>
          )}
        </Form.Item>
      </div>
    </Form>
  );
}

export default Form.create<Props>()(AddressForm);