export const styles = {
  modal: {
    minWidth: '680px',
    minHeight: '460px',
    '& .ant-modal-content': {
      borderRadius: '16px'
    },
    '& .ant-modal-body': {
      padding: '0px !important',
      textAlign: 'center',
      backgroundColor: '#FFFFFF',
      borderRadius: '8px',
      border: '1px solid #F2F2F2',
      display: 'flex',
      alignItems: 'center'
    },
    '& .ant-input': {
      height: '36px !important'
    },
    '& .ant-select-selection--single': {
      height: '36px !important'
    },
    '& .ant-select': {
      width: '242px'
    },
    '& .has-error .ant-form-explain': {
      display: 'flex'
    },
    '& .ant-form-vertical .ant-form-item':{
      width: '-webkit-fill-available'
    },
    '& .ant-input-number-handler-wrap': {
      visibility: 'hidden'
    },
    '& .ant-input-number': {
      width: '-webkit-fill-available',
      height: '36px'
    }
  },
  parent: {
    display: 'flex',
    flexDirection: 'column',
    width: '-webkit-fill-available'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '22px 24px',
    alignItems: 'center',
    borderBottom: '1px solid #CCCCCC'
  },
  leftHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  back: {
    marginRight: '16px',
    fontSize: '16px',
    display: 'flex',
    cursor: 'pointer'
  },
  title: {
    color: '#333333',
    fontSize: '16px',
    fontWeight: 500
  },
  button: {
    borderRadius: '6px',
    backgroundColor: '#111111',
    color: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '10px 24px',
    '&:focus': {
      color: '#FFFFFF',
      backgroundColor: 'rgba(1,1,1,0.8)',
      border: '1px solid #CCCCCC'
    },
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: 'rgba(1,1,1,0.8)',
      border: '1px solid #CCCCCC'
    }
  },
  save: {
    color: '#FFFFFF',
    fontSize: '12px',
    letterSpacing: '0.2px',
    lineHeight: '20px',
    textAlign: 'center'
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px'
  },
  addressLine1Wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '24px'
  },
  addressLine2Wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '24px'
  },
  addressLine3Wrapper: {
    display: 'flex',
  },
  pincode: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '24px',
    alignItems: 'flex-start'
  },
  city: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '24px',
    alignItems: 'flex-start'
  },
  state: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  contact: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '62px'
  },
  addressTitle: {
    marginBottom: '6px'
  }
};