import {
  SET_USER_DETAILS,
  IS_FETCHING_FEED,
  SET_FEED_DATA,
  SET_FEED_DATA_ERROR,
  SET_FILTERS,
  SET_ACTIVITY_LIST,
  SET_ACTIVITY_LIST_ERROR,
  IS_FETCHING_ACTIVITY,
  SET_USER_BEERS,
  SET_CATALOG_DETAILS,
  SET_MASTER_DATA
} from './action.constants';
import { fetchCheersFeed, fetchActivity } from '../api/apis';
import { Catalog } from '../library/Types';

export const setUserDetails = (details: any) => async (dispatch: any) => {
  dispatch({
    type: SET_USER_DETAILS,
    data: details
  });
};

export const setUserBeers = (beers: number) => async (dispatch: any) => {
  dispatch({
    type: SET_USER_BEERS,
    data: beers
  })
}

export const setMasterData = (masterdata: any) => async (dispatch: any) => {
  dispatch({
    type: SET_MASTER_DATA,
    data: masterdata
  })
}

export const fetchActivities = (firstTime: boolean = true) => async (
  dispatch: any,
  getState: any
) => {
  dispatch({
    type: IS_FETCHING_ACTIVITY,
    data: true,
    firstTime
  });
  const activityCurrentPageNumber = firstTime
    ? 1
    : getState().loginReducer.activityCurrentPageNumber;
  const response = await fetchActivity({
    currentPage: activityCurrentPageNumber
  });
  if (response.isSuccess && !firstTime && !response.data?.length) {
    dispatch({
      type: SET_ACTIVITY_LIST_ERROR,
      data: 'No more data!'
    });
  } else if (response.isSuccess) {
    dispatch({
      type: SET_ACTIVITY_LIST,
      data: response.data,
      firstTime
    });
  } else {
    dispatch({
      type: SET_ACTIVITY_LIST_ERROR,
      data: response.errorMessage
    });
  }
  dispatch({
    type: IS_FETCHING_ACTIVITY,
    data: false,
    firstTime
  });
};

export const setFilters = (filters: any) => async (dispatch: any) => {
  dispatch({
    type: SET_FILTERS,
    data: filters
  });
  dispatch(fetchUserFeed());
};

export const fetchUserFeed = (firstTime: boolean = true) => async (
  dispatch: any,
  getState: any
) => {
  dispatch({
    type: IS_FETCHING_FEED,
    data: true,
    firstTime
  });

  const searchFilters = getState().loginReducer.searchFilters;
  const feedCurrentPageNumber = firstTime
    ? 1
    : getState().loginReducer.feedCurrentPageNumber;
  const params = {
    onlyMe: searchFilters?.onlyMe,
    queryString: searchFilters?.queryString,
    currentPage: feedCurrentPageNumber
  };
  const response = await fetchCheersFeed(params);
  if (response.isSuccess && !firstTime && !response.data?.length) {
    dispatch({
      type: SET_FEED_DATA_ERROR,
      data: 'No more data!'
    });
  } else if (response.isSuccess) {
    dispatch({
      type: SET_FEED_DATA,
      data: response.data,
      firstTime
    });
  } else {
    dispatch({
      type: SET_FEED_DATA_ERROR,
      data: response.errorMessage
    });
  }
  dispatch({
    type: IS_FETCHING_FEED,
    data: false,
    firstTime
  });
};

export const setCatalogDetails = (details: Catalog[]) => async (dispatch: any) => {
  dispatch({
    type: SET_CATALOG_DETAILS,
    data: details
  })
}