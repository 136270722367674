import React , {useEffect} from 'react';
import withStyles from 'react-jss';
import { fetchTeams, updateUser, fetchUser } from '../api/apis';
import { useGenericState } from '../library/UseGenericState';
import Loader from '../cheerIcons/Loader.gif';
import { connect } from 'react-redux';
import { Button, message } from 'antd';
import { useHistory } from "react-router-dom";
import { getDashboardRoute, getLogoutRoute } from '../routing/utils';
import { bindActionCreators } from 'redux';
import { setUserDetails } from './Login.actions';
import { Redirect } from 'react-router';

interface IProps {
    classes: any;
    userDetails: any;
    setUserDetails: (details: any) => void;
    forceLogout: boolean;
}

interface Team {
    id: string;
    name: string;
}

interface StateProps {
    teamsList : Team[];
    selectedTeam: number;
    isUpdating: boolean;
    isFetchingUser: boolean;
}

interface TeamProps {
    id: number;
    name: string;
    classes: any;
    selectedTeam: number;
    click: (id: number) => void;
}

const styles = {
    '@media (max-width: 700px)':{
        wrapper: {
            padding: '97px 60px 220px 60px !important',
        },
    },
    '@media (max-width: 570px)':{
        wrapper: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        teamList: {
            justifyContent: 'center'
        }
    },
    wrapper: {
        width: '100%',
        height: '100%',
        padding: '97px 120px 220px 120px'
    },
    selectTeamText: {
        color: '#111111',
        fontSize: '20px',
        fontWeight: 'bold',
        letterSpacing: '0.5px',
        lineHeight: '27px',
    },
    teamList: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '25px',
        gap: '20px'
    },
    team: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxSizing: 'border-box',
        height: '57px',
        width: '216px',
        border: '1px solid #CCCCCC',
        borderRadius: '6px',
        fontSize: '16px',
        fontWeight: 500,
        color: 'black',
        cursor: 'pointer'
    },
    line: {
        boxSizing: 'border-box',
        height: '1px',
        width: '100%',
        border: '1px solid #979797',
        marginTop: '35px'
    },
    buttonWrapper: {
        marginTop: '35px',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    button: {
        height: '56px',
        width: '136px',
        borderRadius: '6px',
        backgroundColor: '#111111',
        color: '#FFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '&:focus': {
            color: '#FFFFFF',
            backgroundColor: 'rgba(1,1,1,0.8)',
            border: '1px solid #CCCCCC'
        },
        '&:hover': {
            color: '#FFFFFF',
            backgroundColor: 'rgba(1,1,1,0.8)',
            border: '1px solid #CCCCCC'
        },
    },
    next: {
        color: '#FFFFFF',
        fontSize: '18px',
        letterSpacing: 1,
        lineHeight: '20px',
        textAlign: 'center',
    }
}

const Team = (props: TeamProps) => {
    const { id, name, selectedTeam, click, classes } = props;
    return (
        <div className={classes.team} style={{ backgroundColor : (selectedTeam===id) ? '#FFD17B' : '#FFFFFF'}} onClick={() => click(id)}>{name}</div>
    );
}

const TeamSelectPage = (props : IProps) => {
    let history = useHistory();
    const { classes, userDetails, setUserDetails, forceLogout} = props;
    const [state, setState] = useGenericState<StateProps>({
        teamsList: [],
        selectedTeam: -1,
        isUpdating: false,
        isFetchingUser: false
    });
    const { teamsList, selectedTeam, isUpdating, isFetchingUser } = state;

    useEffect(() => {
        fetchTeamsData();
        fetchUserData();
    },[]);

    const handleSubmit = async () => {
        const body = {
            name: userDetails?.name,
            email: userDetails?.email,
            teamId: selectedTeam
        }
        setState({isUpdating:true});
        const response = await updateUser(body);
        setState({isUpdating:false});
        if(response.isSuccess){
            window.localStorage.setItem('teamId',selectedTeam.toString());
            setUserDetails({...userDetails, teamId:selectedTeam});
            history.push(getDashboardRoute());
        }
    }

    const fetchTeamsData = async () => {
        const response = await fetchTeams();
        if(response.isSuccess){
            setState({teamsList: response.data});
        }
        else{
            message.error(`Couldn't fetch teams, try again!`);
        }
    }

    const fetchUserData = async () => {
        setState({isFetchingUser:true});
        const response = await fetchUser();
        setState({isFetchingUser:false});
        if(response.isSuccess){
            if(response.data.teamId){
                window.localStorage.setItem('teamId',response.data.teamId);
                history.push(getDashboardRoute());
            }
        }
        else{
            message.error(`Invalid User, try again!`);
        }
    }

    if (forceLogout) {
        return <Redirect to={{ pathname: getLogoutRoute() }} />;
    }

    return (
        <div className={classes.wrapper} style={!teamsList.length ? {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
            } : undefined}> 
            {(!teamsList.length || isFetchingUser)? 
                <img src={Loader} alt="" className={classes.loader}/> : 
                <>
                    <div className={classes.selectTeamText}>Select your team</div>
                    <div className={classes.teamList}>
                    {teamsList.map(team => {
                        return <Team classes={classes} key={team.id} id={parseInt(team.id)} name={team.name} selectedTeam={selectedTeam} click={(id: number) => setState({selectedTeam:id})}/>
                    })}
                    </div>
                    <div className={classes.line}></div>
                    <div className={classes.buttonWrapper}>
                        <Button className={classes.button} onClick={handleSubmit} disabled={selectedTeam<0} loading={isUpdating}>
                            <span className={classes.next} >Next</span> 
                        </Button>
                    </div>
                </>
            }
        </div>
    );
}

const mapStateToProps = ({ loginReducer }: any, ownProps: any) => ({
    userDetails: loginReducer?.userDetails,
    forceLogout: loginReducer?.forceLogout
  });

  const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      setUserDetails
    },
    dispatch
  );
  

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(TeamSelectPage));