import * as React from 'react';
import withStyles from 'react-jss';
import { Address, RouterProps } from '../library/Types'; // eslint-disable-line
import { Modal, Button, message} from 'antd'; 
import {ToastType} from './utils';
import { useGenericState } from '../library/UseGenericState';
import Loader from '../cheerIcons/Loader.gif';
import { deleteUserAddress } from '../api/apis';


interface IProps extends RouterProps {
  classes: any;
  handleClose: (isSucess?:boolean) => void;
  isVisible: boolean;
  address: Address;
  fetchUserAddressData: () => void;
  handleActiveAddress: () => void;
}

const styles = {
  modal: {
    '& .ant-modal-content': {
      borderRadius: '8px',
      width: 'fit-content',
      height: '250px',
      boxShadow: '2px 2px 8px 0 rgba(0,0,0,0.09)'
    },
    '& .ant-modal-body': {
      padding: '9px 16px',
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'space-around'
    }
  },
  removeButton: {
    color: '#111111',
    backgroundColor: '#FFD17B',
    border: '1px solid #111111',
    marginRight: '16px',
    '&:focus': {
      color: '#111111',
      backgroundColor: '#FFD17B',
      border: '1px solid #111111'
    },
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: '#111111',
      border: '1px solid #111111'
    }
  },
  cancelButton:{
    color: '#111111',
    '&:hover': {
        color: '#111111',
    }
  },
  text: {
    color: '#111111',
    fontSize: '18px',
    letterSpacing: 0,
    lineHeight: '19px',
    marginBottom: '4px'
  },
  head: {
    color: '#111111',
    fontSize: '24px',
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: '18px'
      
  },
  hr:{
    height: '1px',
    width: '400px',
    transform: 'scaleY(-1)',
    backgroundColor: '#EDEDED'
  },
  buttonWrapper:{
    display: 'flex'
  }
};

interface StateProps {
  isDeletingAddress: boolean;
}

const DeleteModal = (props: IProps): React.ReactElement => {
  const { classes, handleClose, isVisible, address, fetchUserAddressData, handleActiveAddress } = props;
  const [state, setState] = useGenericState<StateProps>({
    isDeletingAddress: false
  });

  const displayToast = (type:string, err?:string) => {
    if(type===ToastType.SUCCESS){
      message.success({
        content: `Address has been successfully deleted`,
        duration: 4
      });
    }
    if(type===ToastType.ERROR){
      message.error({
        content: `Error occured! ${err}`,
        duration: 4
      });
    }

  };

  const handleDeleteUser = async (addressId: number | undefined) => {
    setState({isDeletingAddress: true});
    const res = await deleteUserAddress(addressId);
    if(res.isSuccess){
      handleClose(true);
      displayToast(ToastType.SUCCESS);
      handleActiveAddress();
      fetchUserAddressData();
    }
    else{
      displayToast(ToastType.ERROR,res.errorMessage);
    }
    setState({isDeletingAddress: false});
  }

  const { isDeletingAddress } = state;

  return (
    <Modal
      className={classes.modal}
      visible={isVisible}
      onCancel={() => handleClose()}
      closable={false}
      footer={null}
      centered
    > 
        <div className={classes.head}>Remove Address</div>
        <div>
            <p className={classes.text}>Are you sure you want to remove this address?</p>
            <p style={{marginBottom: '0px', position: 'relative', top: '20px'}}>Note: Your previous orders will still be delivered to this address if chosen earlier</p>
        </div>
        
        <div className={classes.hr}></div>
        <div className={classes.buttonWrapper}>
            <Button onClick={() => handleDeleteUser(address.id)} className={classes.removeButton} loading={isDeletingAddress}>Yes! Remove</Button>
            <Button onClick={() => handleClose()} className={classes.cancelButton}>Cancel</Button>  
        </div>
    </Modal>
  );
};

export default withStyles(styles)(DeleteModal);