import moment from 'moment';
import * as React from 'react';
import withStyles from 'react-jss';
import { snakeCase } from 'snake-case';
import { TransactionStatusType } from './utils';

const styles = {
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #FCFCFC',
    borderRadius: '8px',
    boxShadow: '2px 2px 4px 0 rgba(0,0,0,0.09)',
    padding: '12px',
    marginBottom: '4px',
    cursor: 'pointer',
  },
  catalogContainer: {
    display: 'flex'
  },
  catalogImage: {
    width: '60px',
    height: '60px',
    marginRight: '16px'
  },
  catalogTitle: {
    marginBottom: '12px',
    fontSize: '14px'
  },
  username: {
    fontSize: '10px'
  },
  date: {
    fontSize: '10px'
  },
  addressContainer: {
    marginBottom: '8px',
    marginTop: '16px'
  },
  address: {
    fontSize: '10px',
    whiteSpace: 'break-spaces',
    fontStyle: 'italic',
    lineHeight: 'normal',
  },
  remark: {
    fontSize: '10px',
    lineHeight: 'normal',
    fontStyle: 'italic'
  },
  addressTitle: {
    fontSize: '10px',
    opacity: '80%'
  },
  remarkTitle: {
    fontSize: '10px',
    opacity: '80%'
  },
  images: {
    objectFit: 'cover',
    width: '60px',
    height: '60px',
  }
}

const KanbanCard = (props: any) => {
  const { classes, status, title, name, line1, line2, city, state, pincode, phone, image_urls, note, color, created_at, accepted_at, order_placed_at, delivered_at, rejected_at, cancelled_at} = props;

  const address = `${line1}, ${line2}, ${city} - ${pincode}, ${state}, ${phone}`;
  const statusDate = `${status === TransactionStatusType.NEW_ORDER ? 'created' : snakeCase(status)}_at`;
  
  const statusDateToDateMap = {
    'created_at': created_at,
    'accepted_at': accepted_at,
    'order_placed_at': order_placed_at,
    'delivered_at': delivered_at,
    'rejected_at': rejected_at,
    'cancelled_at': cancelled_at
  }

  return (
    <div className={classes.cardContainer} style={{backgroundColor: color}}>
      <div className={classes.catalogContainer}>
        <div className={classes.catalogImage}>
          <img src={image_urls[0]} className={classes.images}/>
        </div>
        <div className={classes.catalogInformation}>
          <div className={classes.catalogTitle}>{title}</div>
          <div className={classes.username}>{name}</div>
          <div className={classes.date}>
            {moment(statusDateToDateMap[statusDate]).format('ll')}
          </div>
        </div>
      </div>
      {status === TransactionStatusType.ACCEPTED &&
      <div className={classes.addressContainer}>
        <div className={classes.addressTitle}>Post Address</div>
        <div className={classes.address}>
          {address}
        </div>
      </div>
      }
      {(note?.user || note?.admin) && 
      <div className={classes.remarkContainer} style={{ marginTop: status !== TransactionStatusType.ACCEPTED ? '8px' : '0px'}}>
        {note?.user && <div className={classes.remarkTitle}>Remark</div>}
        <div className={classes.remark}>{note?.admin ? note?.admin : note?.user}</div>
      </div>
      }
    </div>
  );
};

export default withStyles(styles)(KanbanCard);
