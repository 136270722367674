export const getLoginRoute = () => {
  return '/login';
};

export const getLogoutRoute = () => {
  return '/logout';
};

export const getDashboardRoute = () => {
  return '/cheer';
};

export const getSelectTeamRoute = () => {
  return '/team/select';
}

export const getMerchandiseRoute = () => {
  return '/merchandise';
};

export const getAdminRoute = () => {
  return '/admin';
};